@use '../../../modules.scss' as *;

.input {
  box-sizing: border-box;
  appearance: none;
  margin: 0;
}

.label {
  @include font($ff: $ff-primary, $fs: rem(14px), $lh: rem(17px), $fw: 400, $co: $grey);
  box-sizing: border-box;
  background-image: url(../../../images/forms/inputRadio.svg);
  background-repeat: no-repeat;
  background-position: left top 3px;
  background-size: 12px 12px;
  padding-left: 17px;
  display: inline-block;
  text-align: center;

  .input:checked + & {
    background-image: url(../../../images/forms/inputRadioChecked.svg);
  }

  &.isVertical {
    padding: 0 0 20px;
    background-size: 15px;
    background-position: center bottom;
    min-width: 15px;
  }
}
