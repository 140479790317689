@use '../../../modules.scss' as *;

.root{
  &.isHideDesktop{
    display: none;
  }
}

.title {
  background-color: $zingSecondaryColor20;
  padding: 15px 20px 14px;
}

.titleText {
  font-size: 15px;
  color: $zingSecondaryColor40;
  margin: 0;
  text-transform: uppercase;
}

.icon {
  display: none;
}

.tag {
  border: 1px solid grey;
  font-size: 12px;
  padding: 4px 8px;
  margin-top: 14px;
  display: inline-block;
}

.stepperWrapper {
  margin: 25px;
  color: $greyFont;
  button + button {
    margin-top: 15px;
  }
}

@include media-breakpoint-down(sm) {
  .root{
    &.isHideMobile{
      display: none;
    }
    &.isHideDesktop{
      display: block;
    }
  }
  .title {
    position: relative;
    padding: 15px;
    margin: 0;
  }

  .icon {
    position: absolute;
    top: 21px;
    right: 15px;
    display: block;
    fill: $zingSecondaryColor40;
    transition: transform 0.3s ease-in;

    .root.isClose & {
      transform: rotate(180deg);
    }
  }

  .content {
    display: grid;
    grid-template-rows: 1fr;
    transition: all 0.3s ease-in;

    .root.isClose & {
      grid-template-rows: 0fr;
    }
  }

  .animationWrapper {
    overflow: hidden;
  }

  .tag {
    display: none;
  }
}
