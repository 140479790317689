.paymentsSetting {
  display: flex;
  flex-direction: column;
  width: 100%;

  .cost {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .textArea {
    margin-bottom: 1.5rem;
  }
}
