@use '../../modules.scss' as *;

%title {
  font-size: 20px;
  font-weight: normal;
  margin: 0 0 20px;
  z-index: 200;
}

.title_fnc {
  @extend %title;
  color: $primaryColor;
}

.title_zing {
  @extend %title;
  color: $zingPrimaryColor;
}

@include media-breakpoint-down(sm) {
  .root {
    @include font($ff: $ff-secondary, $fs: rem(16px), $lh: rem(18px), $fw: 400, $co: $white);
    padding: 15px;
    margin: 0;
  }
}
