@use '../../modules.scss' as *;

.radioGroupRow {
  padding-left: 10px;
  width: 100%;

  fieldset {
    margin-bottom: 30px;
    legend {
      line-height: 25px;
    }
    [role='radiogroup'] {
      gap: 0;
      padding-top: 10px;
      padding-left: 10px;
      label {
        margin-bottom: 5px;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .button-save {
    width: 100%;
    height: 50px;
  }
}

.enrollment {
  margin-top: 1em;
  margin-bottom: 1em;
}
