@use '../../../modules.scss' as *;

.error {
  @include font($ff: $ff-primary, $fs: rem(16px), $lh: rem(24px), $fw: 700, $co: $primaryColor);

  * + & {
    margin-top: 6px;
  }
}

@include media-breakpoint-down(sm) {
  .error {
    @include font($fs: rem(14px), $lh: rem(16px));
  }
}
