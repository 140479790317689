@use '../../modules.scss' as *;

.root {
  display: flex;
  flex-wrap: wrap;
  padding: 8px 25px 0;
  list-style-type: none;
  border-top: 1px solid $greyFont;
  width: calc(100% + 50px);
  margin: auto -25px 0;
  box-sizing: border-box;
}

.menuLanguageItem {
  & + & {
    margin-left: 10px;
  }
}

.menuLanguageText {
  @include font($ff: $ff-primary, $fs: rem(14px), $lh: rem(20px), $fw: 400, $co: $grey);
}

@include media-breakpoint-down(sm) {
  .root {
    border-top: 1px solid $greyDark;
    padding: 8px 13px;
    width: 100%;
    margin: 0;

    * + & {
      margin-top: 10px;
    }
  }

  .menuLanguageText {
    @include font($ff: $ff-primary, $fs: rem(12px), $lh: rem(20px), $fw: 400, $co: $grey);
  }

  .menuLanguageLink {
    @include font($co: $grey);
    text-decoration: none;
    padding: 7px 7px;
  }
}
