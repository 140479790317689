.followUpListContainer {
  padding: 20px;
  max-width: 1920px;
  min-height: 425px;

  .listContainer {
    max-height: 500px;
    overflow: auto;
    display: flex;
    flex-direction: column;
  }
}

.followUpListContainer [class*='tableWithEdit_container'] {
  padding: 0;
}

.keysContainer {
  display: flex;
  flex-direction: row;
  // padding-left: 20px;
  // padding-right: 40px;

  div {
    flex: 1;
  }
}

.followUpInfo {
  color: #666666;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}

.followUpText {
  align-self: center;
}

.itemsContainer {
  border: 1px solid #e7e7e7;
  display: flex;
  cursor: pointer;

  .title,
  .date,
  .user_id,
  .description,
  .file {
    flex: 1;
    padding: 40px 0px;
  }

  &:hover {
    background: blue;
  }
}
