.fixed {
	width: 5em;
	position: sticky;
	z-index: 3;
	background-color: white !important;
	top: auto;
	padding: 16px 5px;
}

.tableHeadCell {
	// width: 106px !important;
	height: 25px !important;
	background-color: white !important;
	top: 0 !important;
	padding: 16px 9px;
	z-index: 2;
	position: sticky;
}

.stickyHeader {
	top: 0;
	z-index: 2;
	position: sticky;
	background-color: #fafafa;
}
