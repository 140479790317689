@use '../../../modules.scss' as *;

.subItem {
  margin-left: 3rem;
}

.item_details {
  display: flex;
  align-items: center;
  color: $greyFont;
}

.tag_type {
  margin: 0 20px;
  border: 1px solid $greyFont;
  text-transform: uppercase;
  font-size: 12px;
  padding: 2px 6px;
}

.list_item {
  border-top: 2px solid #3333 !important;
}
