@use '../../modules.scss' as *;

.parraf {
  font-size: 1.5em;
  color: $greyFont;
  margin-top: 2%;
  .access_fnc {
    color: $primaryColor;
    cursor: pointer;
  }
  .access_zing {
    color: $zingPrimaryColor;
    cursor: pointer;
  }
}

.container {
  margin-top: -6%;
  color: $greyFont;
  .access_fnc {
    color: $primaryColor;
    cursor: pointer;
  }
  .access_zing {
    color: $zingPrimaryColor;
    cursor: pointer;
  }
}

.container_parraf {
  margin-top: 122px;
}
