@use '../../../modules.scss' as *;
// .tableCell {
//   width: 106px;
//   height: 25px;
// }

// .input {
//   width: 106px;
//   height: 25px;
//   padding-left: 3px;
//   font-size: 13px;
//   input {
//     padding: 3px 0 7px !important;
//   }
// }

// .date {
//   width: 106px;
//   height: 25px;
//   padding-left: 3px;
//   font-size: 13px;
//   min-height: 0 !important;
//   padding: 0 0 7px !important;
//   input {
//     padding: 3px 0 7px !important;
//   }
// }
// .textfield_filter {
//   [class*='MuiTextField-root'] {
//     min-height: 0px;
//   }
//   [class*='MuiOutlinedInput-root'] {
//     height: 34px;
//     font-size: 1em;
//     border-radius: 18px;
//     border: 1px solid #6666661a;
//     margin-bottom: 0;
//   }
//   [class*='MuiSvgIcon-root'] {
//     font-size: 1rem;
//     color: grey;
//   }
// }

// .textfield_filter_hidden {
//   display: none;
//   [class*='MuiTextField-root'] {
//     min-height: 0px;
//   }
//   [class*='MuiOutlinedInput-root'] {
//     height: 34px;
//     font-size: 1em;
//     border-radius: 18px;
//     border: 1px solid #6666661a;
//   }
//   [class*='MuiSvgIcon-root'] {
//     font-size: 1rem;
//     color: grey;
//   }
// }

// .textfield_search {
//   [class*='MuiTextField-root'] {
//     min-height: 0px;
//   }
//   [class*='MuiOutlinedInput-root'] {
//     height: 38px;
//     font-size: 1em;
//     border-radius: 18px;
//     border: 1px solid #6666661a;
//     width: 241px;
//     margin-bottom: 0;
//   }
//   [class*='MuiSvgIcon-root'] {
//     font-size: 1.5rem;
//     color: grey;
//   }
// }

// .iconDis {
//   color: #80808063 !important;
// }

// .icon {
//   cursor: pointer;
// }

// .tableHeadFilter {
//   width: 114px !important;
//   height: 25px !important;
//   background-color: white !important;
//   top: 57px !important;
//   padding: 16px 9px !important;
//   z-index: 2;
//   position: sticky;
// }

// .tableHeadFilterFixed {
//   width: 5em !important;
//   position: sticky !important;
//   z-index: 3 !important;
//   background-color: white !important;
//   top: 57px !important;
//   padding: 16px 5px !important;
// }

// .button {
//   width: 320px;
//   margin-left: 5px !important;
// }

.input_border {
  border: 1.5px solid rgba(120, 128, 138, 0.3) !important;
  border-radius: 20px;
}

.textfield_search {
  margin: 0 12px;
  @extend .input_border;
}

.textfield_filter {
  @extend .input_border;
}

.toolbar {
  padding-top: 12px;
  padding-bottom: 12px;
  box-sizing: border-box;
  border-bottom: 1px solid rgb(224, 224, 224);
}

.textarea {
  border: 1px solid $redLightColor !important;
  padding-left: 2px !important;
}

/* .container_textarea {
	position: relative !important;
} */
