.refuse {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-top: 10px;
  :global(.MuiFormControl-root) {
    width: 95%;
    margin: 0;
  }
  .btns {
    width: 100%;
    margin-top: 1%;
    display: flex;
    justify-content: space-around;
    div {
      margin: 0;
      margin-right: 3px;
      margin-left: 3px;
    }
  }
}
