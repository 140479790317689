.filterListContainer {
  padding: 20px 0px;
}

.textContainer {
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;

  div {
    flex: 0 0 47%;
  }
}

.title {
  font-weight: bold;
  color: #d64a45;

  svg {
    vertical-align: text-bottom;
    margin-left: 5px;
    width: 18px;
    width: 18px;
  }
}

.noResults {
  border: 1px solid gray;
  text-align: center;
  color: #666666;
  padding: 20px;
  border-radius: 4px;
  background: rgba(102, 102, 102, 0.05);
}
