@use '../../modules.scss' as *;

.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.main_menus {
  overflow: auto;
  height: auto;
  display: flex;
  flex-grow: 1;

  main {
    flex: 1 auto 0;
    overflow: auto;
    background: #fafafa;
    display: flex;
    flex-direction: column;
  }
}

.sub_header {
  height: 4.8vh;
}

@include media-breakpoint-down(sm) {
  .main_menus {
    display: block;
    margin-top: ele-headerHeight(mobile);

    main {
      min-height: 100%;
    }
  }

  .container {
    min-height: 100%;
    width: auto;
  }
}
