@use '../../modules.scss' as *;

.root {
  width: 100%;

  h4 {
    margin: 0;
  }
}
.stepper {
  display: flex;
  width: 100%;
  margin: 10px 0;
}

.step {
  flex-grow: 1;
  height: 8px;
  background-color: #b9b9b9;
  width: 20px;

  & + & {
    margin-left: 2px;
  }
}

.filled {
  background: $redLightColor;
}

.title {
  font-weight: normal;
}

.stepNumber {
  font-weight: bold;
}

.status {
  margin: 0;

  &.isMobile {
    display: none;
  }
}

@include media-breakpoint-down(sm) {
  .title {
    @include font($ff: $ff-secondary, $fs: rem(14px), $lh: rem(22px), $fw: 400, $co: $grey);
  }

  .stepNumber {
    font-weight: 600;
  }

  .step {
    height: 7px;
  }

  .status {
    @include font($ff: $ff-primary, $fs: rem(14px), $lh: rem(22px), $fw: 400, $co: $grey);

    &.isMobile {
      display: inline-block;
    }
  }
}
