@use '../../modules.scss' as *;

.languageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  .language {
    .flag {
      width: 70%;
      height: 70%;
    }
  }
}

@include media-breakpoint-down(sm) {
  .hiddenInMobile {
    display: none;
  }
}
