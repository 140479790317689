@use '../../../../modules.scss' as *;

.container {
  width: 300px;
  padding: 10px;
}

.newcontact {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.uploadFileButton {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.btn_create {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@include media-breakpoint-down(sm) {
  .container {
    width: 240px;
    padding: 10px;
  }
}
