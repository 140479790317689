@use '../../modules.scss' as *;

.root {
  padding: 40px 40px 40px;

  > .mobileSubtitle {
    margin-top: 20px;
  }
}

@include media-breakpoint-down(sm) {
  .root {
    padding: 20px;

    [class*='tableWithEdit_container'] {
      display: none;
    }

    > .submenuInternalProjects {
      margin-top: 20px;
      height: 50px;
    }
  }

  .title {
    display: block;
    @include font($ff: $ff-primary, $fs: rem(24px), $lh: rem(28px), $fw: 400, $co: $primaryColor);
  }
}
