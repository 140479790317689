.closingYear {
    display: inline-flex;
    justify-content: flex-start;
    align-items: baseline;
    gap: 1rem;
}

.closingYearSelector {
    width: 8rem;
    text-align: left;
}

.closingYearButton {
    width: 8rem;
}