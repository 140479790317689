@use '../../modules.scss' as *;

@include media-breakpoint-down(sm) {
  .root {
    padding: 20px 0 0 0;

    > .content {
      padding: 20px 0 0 0;
      display: grid;
      grid-template-columns: auto;
      grid-gap: 20px;
    }

    [class*='inputSearch_root'] {
      padding: 0;
      margin: 0;
    }

    [class*='mobileTable_root'] {
      padding: 0;
      margin: 0;
    }

    > .mobileSubtitle {
      margin-top: 20px;
    }

    [class*='tableWithEdit_container'] {
      display: none;
    }

    > .submenuInternalProjects {
      margin-top: 20px;
      height: 50px;
    }
  }

  .title {
    display: block;
    @include font($ff: $ff-primary, $fs: rem(24px), $lh: rem(28px), $fw: 400, $co: $primaryColor);
  }
}