.formContainer{
  // background-color: red;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-top: 10px;
  :global(.MuiFormControl-root) {
    width: 95%;
    margin: 0;
  }
  .searchContainer{
    width: 100%;
    display: grid;
    grid-template-columns: 2.5fr 1.5fr 1fr;
    gap: 2rem;
    
    [class*='MuiButton']{
      height: 70%
    }
  }
  .changeContainer{
    width: 100%;
    display: grid;
    grid-template-columns: 2.5fr 1.5fr 1fr;
    gap: 2rem;
    
    [class*='MuiButton']{
      grid-column: span 1 / 4;
      height: 70%
    }
  }
  [class*='MuiButton-label']{
    align-items: normal
  }
}
