@use '../../../modules.scss' as *;

p {
  margin: 0;
}

.title {
  color: $primaryColor80;
  font-size: 24px;
  background: rgba(102, 102, 102, 0.1);
  padding: 20px 30px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;

  :global(.MuiDialogTitle-root) {
    padding: 0;
    display: flex;

    h2 {
      line-height: 1;
      font-weight: 600;
      padding: 0px;
    }
  }
}

.dialogContent {
  padding: 0px !important;
  width: 500px;
}

.btn_create {
  display: flex;
  justify-content: flex-end;
  [class*='MuiButtonBase'] {
    width: 20%;
    margin-left: 10px;
  }
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 25px 30px 40px;

  :global(.MuiFormControl-root) {
    width: auto;
    flex-grow: 1;
    margin: 0;
    margin-bottom: 0px;

    & + :global(.MuiFormControl-root) {
      margin-left: 10px;
    }
  }
}

@include media-breakpoint-down(sm) {
  .dialogContent {
    width: auto;
  }
}
