@use '../../../modules.scss' as *;

.Dropzone {
  min-height: 193px;
  width: 99.5%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 16px;
  border: 1px dashed rgba(102, 102, 102, 0.3);
}

.Highlight {
  @extend .Dropzone;
  background-color: rgb(188, 185, 236);
}

.Icon {
  opacity: 0.3;
  height: 64px;
  width: 64px;
  border: 1px solid red;
}

.FileInput {
  display: none;
}

.itemList {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 5px;
}

.itemList_fnc {
  @extend .itemList;
  color: $secondaryColor80;
}

.itemList_zing {
  @extend .itemList;
  color: $zingSecondaryColor80;
}

.itemListDisabled {
  @extend .itemList;
  pointer-events: none;
  color: $greenBubble;
}

.item {
  margin-top: 5px;
  display: flex;
}

.uploadContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  svg {
    height: 2em;
    width: 2em;
  }
}

.uploadContainer_fnc {
  @extend .uploadContainer;
  svg {
    color: $secondaryColor80;
  }
}

.uploadContainer_zing {
  @extend .uploadContainer;
  svg {
    color: $zingSecondaryColor80;
  }
}

.here {
  font-weight: bold;
  & :hover {
    text-decoration: underline;
  }
}

.here_fnc {
  @extend .here;
  color: $primaryColor80;
}

.here_zing {
  @extend .here;
  color: $zingPrimaryColor80;
}

.disabled {
  @extend .Dropzone;
  pointer-events: none;
  opacity: 0.3;
  background: lightgray;
}
