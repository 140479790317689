.container {
  /*   [class*='MuiFormControl'] {
    width: 50% !important;
  } */
  [class*='MTableToolbar-searchField'] {
    min-width: 15%;
    max-width: 20%;
  }
  [class*='MTableToolbar-actions'] {
    flex: none;
  }
  &__modal {
    margin: 10px;
    margin-left: 0;
  }
}

.list {
  &__check {
    width: 45px;
    color: #0000008a !important;
  }
  &__span {
    margin: 10px;
  }
}
