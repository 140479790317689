@use '../../../modules.scss' as *;

.form {
  padding: 40px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  &__checkbox {
    width: 30%;
  }
}
