.form_payments {
  padding: 20px 20px 0px 20px;
  margin-left: auto;
  margin-right: auto;
}

.filter_grid {
  display: grid;
  grid-template-columns: auto 200px 200px;
  grid-template-rows: 55px 55px auto;
  grid-gap: 10px;
}

.filter_selectors {
  display: inline-flex;
  gap: 20px;
}

.academic_year_selector {
  width: 250px;
}

.status_selector {
  width: 250px;
  margin-left: 10px;
}

.entity_selector {
  width: 90%;
}

.summary_remainder {
  grid-column: 3 / 4;
}


.search_replace_container {
  display: flex;
  gap: 20px;
  justify-content: flex-end;
}

.buttonReplace {
  width: 140px;
  height: 51px;
}
