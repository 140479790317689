@use '../../modules.scss' as *;

.questionText {
  margin-bottom: 20px;
  font-size: 1rem;
}

.questionTextLeft {
  @extend .questionText;
  max-width: 26vw;
  line-height: 1.5em;
}

.options {
  color: $greyFont;
  margin-bottom: 20px;
}

.optionsModal {
  color: $greyFont;
  //margin-bottom: 10px;
  font-size: 1rem;
}
.answer {
  width: 50%;
  display: flex;
  justify-content: space-around;
}
.questionLeft {
  width: 55vw;
  display: flex;
  flex-direction: row !important;
  align-items: center;
  justify-content: space-between;
  margin-left: 8px;
  margin-bottom: 5px;
}
.radioLabelTop {
  width: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.question {
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
}
.bottomSpace {
  width: 100%;
  height: 80px;
}
