@use '../../modules.scss' as *;

@include media-breakpoint-down(sm) {
  .root {
    background: $white;
    width: 100%;
    border: 1px solid $greyLight;
    border-radius: 4px;

    * + & {
      margin-top: 14px;
    }
  }

  .th,
  .td {
    padding: 12px 15px 12px 37px;
    overflow: hidden;

    & + & {
      padding-left: 15px;
    }
  }

  .td {
    position: relative;

    &.isSingleCell {
      padding-left: 15px;
    }

    .row & {
      border-top: 1px solid $greyLight;
    }
  }

  .heading {
    @include font($ff: $ff-primary, $fs: rem(14px), $lh: rem(17px), $fw: 500, $co: $greyT);
    text-align: left;
    white-space: nowrap;
  }

  .bullet {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 15px;
    margin: auto;

    &.isOK {
      fill: $greenBubble;
    }

    &.isKO {
      fill: $primaryColor80;
    }
  }

  .fileName {
    @include font($ff: $ff-primary, $fs: rem(14px), $lh: rem(17px), $fw: 400, $co: $secondaryColor);
    text-overflow: ellipsis;
  }

  .fileLastUpdate {
    @include font($ff: $ff-primary, $fs: rem(12px), $lh: rem(15px), $fw: 400, $co: $grey);
  }
}
