.editor {
  min-height: 200px;
  background: white;
  border: 1px solid rgba(118, 118, 118, 0.6);
  padding: 10px;
  font-size: 14px;
}

.editorContainer {
  flex: 0 0 62%;
}
