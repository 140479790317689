@use '../../modules.scss' as *;

.container {
  background: #fafafa;
  min-width: 700px;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 40px 40px 40px;
  max-width: 1334px;
  width: 100%;
}

.containerMenuRight {
  @extend .container;
  padding-left: 43px;
}

@include media-breakpoint-down(sm) {
  .container {
    padding: 20px;
    background-color: $white;
    min-width: initial;
  }
}
