@use '../../modules.scss' as *;

.autocomplete {
  width: 100%;
}

.comboContainer {
  width: 49%;
  position: relative;
}

.comboContainerFull {
  width: 95%;
}

.input {
  height: 52px !important;
}

.hasClipBoard {
  & + .clipBoard {
    position: absolute;
    top: 1px;
    right: 0px;
    z-index: 1;
  }

  & [class*='MuiAutocomplete-clearIndicator'] {
    right: 30px;
  }

  & [class*='MuiAutocomplete-popupIndicator'] {
    right: 30px;
  }

  [class*='MuiIconButton-root'] path:last-child {
    color: rgba(128, 128, 128, 0.708);
  }
}
@include media-breakpoint-down(sm) {
  .comboContainer {
    width: 100%;

    .clipBoard {
      display: none;
    }
  }

  .hasClipBoard {
    & [class*='MuiAutocomplete-popupIndicator'] {
      right: -5px;
      top: -4px;
    }
  }
}
