@use '../../../modules.scss' as *;

.root {
  height: $footerheight;
  flex-shrink: 0;
  border-top: 1px solid #cccccc;
  box-sizing: border-box;
  position: relative;
}

.list {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 0;
}

.item {
  color: $greyFont;
  font-size: 0.8em;
  margin: 0 20px;
  cursor: pointer;

  &:hover {
    color: $redLightColor;
  }
}

.link {
  color: $greyFont;
  text-decoration: underline;
}

@include media-breakpoint-down(sm) {
  .root {
    height: 45px;
    padding: 10px 20px;

    [class*='MuiButtonBase-root'] {
      display: none;
    }
  }

  .list {
    justify-content: space-between;
  }

  .item {
    @include font($ff: $ff-primary, $fw: 400, $fs: rem(12px), $lh: 28px);
    margin: 0;

    & + & {
      margin-left: 10px;
    }
  }

  .link {
    text-decoration: none;
  }
}
