@use '../../modules.scss' as *;
@import '../Inputs/inputs.module.scss';

div {
  .disabledColor {
    * > * {
      color: #af9fa7;
    }
    label {
      color: #af9fa7;
    }
  }
}

.hasClipBoard {
  .clipBoard {
    position: absolute;
    top: 1px;
    right: 0px;
    z-index: 1;
  }

  [class*='MuiSelect-icon'] {
    right: 50px;
  }

  [class*='MuiSelect-iconOutlined'] {
    right: 45px;
  }

  [class*='MuiIconButton-root'] path:last-child {
    color: rgba(128, 128, 128, 0.708);
  }
}

@include media-breakpoint-down(sm) {
  .clipBoard {
    .hasClipBoard & {
      display: none;
    }
  }

  .hasClipBoard {
    [class*='MuiSelect-iconOutlined'] {
      right: 7px;
    }
  }
}
