@use '../../modules.scss' as *;

.wrapper {
  background-color: white;
  padding: 0px;
  margin: 0 20px;

  & + [class*='tableWithEdit_container'] {
    padding-top: 0;
  }
}

.tabs {
  width: 900px;
}

.tab_selected {
  background: $redLightColor !important;
  color: white !important;
}
