@use '../../modules.scss' as *;

.container {
  color: $greyFont;
  width: 60vw;
  min-width: 350px;
  display: flex;
  flex-direction: column;

  .title {
    color: $redLightColor !important;
    font-size: 24px;
    background: rgba(102, 102, 102, 0.1);
    padding: 30px;
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    flex-direction: row;

    :global(.MuiDialogTitle-root) {
      padding: 0px 12px 0px;
      display: flex;

      h2 {
        line-height: 1;
        font-weight: 600;
      }
    }
  }
}

.content {
  margin: 20px 50px 30px 40px;
  text-align: justify;
  line-height: 1.8;
  display: flex;
  flex-direction: column;

  & > p {
    margin-bottom: 2rem;
  }
}

.titleRed h2 {
  color: $redLightColor !important;
}
