.container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.buttonContainer {
  flex-direction: row;
  min-width: 15%;
  margin-left: 20px;
  margin-bottom: 22px;
}

.textContainer {
  width: 55%;
}
