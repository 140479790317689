@use '../../../../../modules.scss' as *;

.title {
  color: $primaryColor80;
  font-size: 24px;
  background: rgba(102, 102, 102, 0.1);
  padding: 20px 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;

  :global(.MuiDialogTitle-root) {
    padding: 0;
    display: flex;

    h2 {
      line-height: 1;
      font-weight: 600;
      padding: 0px;
    }
  }
}

.dialogContent {
  padding: 20px !important;
  width: 900px;
}

.form {
  width: 100%;
}

.checkboxSection {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px -10px 25px 10px;

  [class*='PrivateSwitchBase-input'] {
    left: 0;
  }
  .checkboxSectionText {
    min-width: fit-content;
    margin-right: 20px;
    color: #808080;
  }
}

.btn_create {
  display: flex;
  justify-content: flex-end;
  [class*='MuiButtonBase'] {
    width: 20%;
    margin-left: 10px;
  }
}

@include media-breakpoint-down(sm) {
  .dialogContent {
    width: auto;
  }
}
