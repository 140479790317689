@use '../../modules.scss' as *;

%actions {
  display: flex;
  justify-content: space-around;
  a {
    font-size: 15px;
    line-height: 12px;
    text-decoration: underline;
    color: $primaryColor;
  }
}

.actions_fnc {
  @extend %actions;
  a {
    color: $primaryColor;
  }
}

.actions_zing {
  @extend %actions;
  a {
    color: white;
  }
}

.login {
  height: 85%;
  margin-bottom: 15px;
}
