@use '../../../../modules.scss' as *;

.justificationTableContainer {
  width: 100%;
}

.tableTitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.addNewJustification {
  text-align: right;
}

.justificationRow {
  border: 1px solid  $secondaryColor20;
}


.justificationTableRow {
  @extend .justificationRow;
}

.justificationTableRow:hover {
  background-color: rgba(0, 0, 0, 0.04);
  transition: 0.9s;
}

.justificationTableHeader {
  @extend .justificationRow;
  border: 1px solid  $secondaryColor20;
}

.justificationTable {
  border-collapse: collapse;
  border: 1px solid  $secondaryColor20;
  margin: 20px 0px;
  font-size: 14px;
  width: inherit;
  background-color: white;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  box-shadow: 0px 1px 1px 0px $secondaryColor40;
}


.justificationCellHeader {
  padding: 20px;
  text-align: left;
  color: $secondaryColor80;
}

.justificationCellRow {
  padding: 10px;
  text-align: left;
}

.descriptionCell {
  width: 100%;
  text-align: left;
}

.justificationTypeCell {
  width: 100px;
  text-align: left;
}

.amountCell {
  width: 100px;
  text-align: right;
}

.actionCell {
  display: flex;
  margin-right: 5px;
}

.justificationEmptyCellRow {
  padding: 20px;
  text-align: center;
}

.tableMessageArea {
  padding-bottom: 20px;
}

.justificationError {
  color: $redLightColor;
  font-size: 0.9rem;
}

@include media-breakpoint-down(sm) {
  .justificationTable {
    font-size: 0.8rem;
  }
  .actionCell {
    width: 50px;
  }
  .addNewJustification {
    margin-top: 20px;
  }
 }
