@use '../../../../modules.scss' as *;

.modalContainer {
  [class*='MuiDialog-paperWidth'] {
    width: 60%;
    height: 46%;
  }

  .formContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .attachmentContainer {
      width: 100%;

      .chip svg {
        margin-left: 10px;
      }

      .buttonContainer input {
        display: none;
      }

      .buttonContainer div {
        width: 200px;
      }
    }
  }
}
