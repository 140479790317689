@use '../../modules.scss' as *;

.documentContainer {
  width: 100%;
}

.documentNew {
  text-align: right;
}

.documentTableCaption {
  padding: 20px 0px;
  caption-side: bottom;
  text-align: left;
  color: $greyFont;
  font-size: 1rem;
}

.documentRow {
  border: 1px solid  $secondaryColor20;
}

.documentRowActions {
  display: flex;
  justify-content: flex-end;
}

.documentTableRow {
  @extend .documentRow;
}

.documentTableRow:hover {
  background-color: rgba(0, 0, 0, 0.04);
  transition: 0.9s;
}

.documentTableHeader {
  @extend .documentRow;
  border: 1px solid  $secondaryColor20;
}

.documentTable {
  border-collapse: collapse;
  border: 1px solid  $secondaryColor20;
  margin: 20px 0px;
  font-size: 14px;
  width: inherit;
  background-color: white;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  box-shadow: 0px 1px 1px 0px $secondaryColor40;
}


.documentCellHeader {
  padding: 20px;
  text-align: left;
  color: $secondaryColor80;
}

.documentCellRow {
  padding: 20px;
  text-align: left;
}

.documentFileCell {
  width: 100%;
}

.documentStatusCell {
  width: 150px;
}

.documentDateCell {
  width: 150px;

 @include media-breakpoint-down(sm) {
   .documentTableCaption {
     font-size: 0.875rem;
   }
 }

}

.documentEmptyCellRow {
  padding: 20px;
  text-align: center;
}
