.list {
  &__check {
    width: 45px;
    color: #0000008a !important;
  }
  &__downloadButton {
    border: none;
    background: none;
    width: auto;
  }
}
