@use '../../../modules.scss' as *;

.dropzone {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 16px;
  border: 1px dashed rgba(102, 102, 102, 0.3);
  background-color: #fff;
  cursor: pointer;
  height: 193px;
  width: 99.5%;
}

.highlight {
  @extend .dropzone;
  background-color: rgb(188, 185, 236);
}

.disabled {
  @extend .dropzone;
  cursor: default;
  opacity: 0.3;
  background: lightgray;
}

.fileList {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 5px;
}

.fileListDisabled {
  @extend .fileList;
  pointer-events: none;
  color: $greenBubble;
}

.fileList_fnc {
  @extend .fileList;
  color: $secondaryColor80;
}

.fileList_zing {
  @extend .fileList;
  color: $zingSecondaryColor80;
}

.previewImage {
  width: 100%;
  height: 100%;
}

.uploadContainer {
  display: flex;
  flex-direction: column;
  align-items: center;

  svg {
    height: 2em;
    width: 2em;
  }
}

.uploadContainer_fnc {
  @extend .uploadContainer;

  svg {
    color: $secondaryColor80;
  }
}

.uploadContainer_zing {
  @extend .uploadContainer;

  svg {
    color: $zingSecondaryColor80;
  }
}

.here {
  font-weight: bold;

  & :hover {
    text-decoration: underline;
  }
}

.here_fnc {
  @extend .here;
  color: $primaryColor80;
}

.here_zing {
  @extend .here;
  color: $zingPrimaryColor80;
}
