.containerConfig {
  width: 100%;
  box-sizing: border-box;
  padding-top: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;

  [class*='containerList'] {
    margin-right: 25%;
  }

  [class*='layoutForm_container'] {
    max-width: 1450px;
    padding: 40px 20px 10px;
  }
}

.config {
  padding-left: 10%;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 12%;
  }
}
