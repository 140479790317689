@use '../../../modules.scss' as *;

.curriculum label {
  &.inputLabel {
    display: flex;
    align-items: center;
    margin: 0 0 12px;

    svg {
      margin-right: 4px;
    }
  }
}

.curriculum {
  a {
    color: inherit;
    text-decoration: none;
    font-weight: bold;
  }
  [class*='MuiFormControlLabel-root'] {
    align-items: flex-start;
    margin-bottom: 20px;
  }
  margin-bottom: 14px;
}

.label {
  @include font($ff: $ff-primary, $fs: rem(14px), $lh: rem(22px), $fw: 400, $co: $greyFont);
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
}

.labelText {
  * + & {
    margin-left: 10px;
  }
}

.icon {
  fill: $primaryColor;
  flex-shrink: 0;
}

@include media-breakpoint-down(sm) {
  .dataProtect {
    font-size: 10px;
    line-height: 16px !important;
    margin-bottom: 15px !important;
    a {
      color: $zingPrimaryColor !important;
    }
  }

  .curriculum {
    margin: 0;
  }

  .label {
    margin: 0;

    * + & {
      margin-top: 14px;
    }
  }
}
