@import './modules';

@font-face {
  font-family: Odudo;
  src: url('fonts/Odudo-Regular.otf') format('opentype');
}

@font-face {
  font-family: Odudo;
  font-weight: bold;
  src: url('fonts/Odudo-Bold.otf') format('opentype');
}

@font-face {
  font-family: Odudo;
  font-weight: lighter;
  src: url('fonts/Odudo-Light.otf') format('opentype');
}

@import url('https://fonts.googleapis.com/css?family=Fira+Sans|Oswald');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

body {
  margin: 0;
  font-family: 'Fira Sans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fnc {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Odudo';
    font-weight: 400;
  }
}

.zing {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Oswald';
    font-weight: normal;
  }
}

html,
body,
#root {
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

.zing,
.fnc {
  min-height: 100vh;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/*Remove arrows in numeric input*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
  text-align: right;
}

input[type='search']::-webkit-search-cancel-button {
  appearance: none;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAAn0lEQVR42u3UMQrDMBBEUZ9WfQqDmm22EaTyjRMHAlM5K+Y7lb0wnUZPIKHlnutOa+25Z4D++MRBX98MD1V/trSppLKHqj9TTBWKcoUqffbUcbBBEhTjBOV4ja4l4OIAZThEOV6jHO8ARXD+gPPvKMABinGOrnu6gTNUawrcQKNCAQ7QeTxORzle3+sDfjJpPCqhJh7GixZq4rHcc9l5A9qZ+WeBhgEuAAAAAElFTkSuQmCC);
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-size: 50% 50%;
  background-position: center;
  opacity: 0.5;
}
@include media-breakpoint-down(sm) {
  * {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }

  table {
    border-spacing: 0px;
  }

  #root {
    overflow: auto;
  }
}
