.buttonContainer {
  margin-bottom: 3rem;
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
}

.tablesContainer {
  display: flex;
  margin-bottom: 1rem;

  >div {
    width: 50%;
  }
}

.fullWidth {
  >div {
    width: 100%;
  }
}

.tablesContainer tr>td:nth-child(3) {
  padding-left: 1rem !important;
  color: #666666;
}