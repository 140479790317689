@use '../../../modules.scss' as *;

.container {
  width: 100%;
  margin-bottom: 1.5%;
}
.compoundItem {
  width: 100%;
  margin: 0 0 2rem 0;
  margin-bottom: -1rem;

  label {
    font-size: 1.1em;
    color: $greyFont;
    margin-bottom: 1rem;
    padding-left: 0.5rem;
    border-left: 6px solid $redLightColor;
  }
  textarea {
    border-radius: 0.5rem;
    width: 100%;
  }
}

.compoundDoc {
  width: 100%;
  label {
    font-size: 1.1em;
    color: $greyFont;
    padding-left: 0.5rem;
    border-left: 6px solid $redLightColor;
  }
}

.compound {
  width: 100%;
  margin: 0 0 2rem 0;

  label {
    font-size: 1.1em;
    color: $greyFont;
    margin-bottom: 1rem;
    padding-left: 0.5rem;
    border-left: 6px solid $redLightColor;
  }
  textarea {
    border-radius: 0.5rem;
    width: 100%;
  }
}

.compoundArea {
  width: 100%;
  margin-bottom: 0.5rem;
  label {
    font-size: 1.1em;
    color: $greyFont;
    padding-left: 0.5rem;
    border-left: 6px solid $redLightColor;
  }
  textarea {
    border-radius: 0.5rem;
    width: 100%;
  }
}

.form {
  width: 98%;
  padding-left: 1%;
  padding-right: 1%;
  [class*='paddingCheckbox'] {
    width: 127px !important;
  }
  [class*='tableWithEdit_container'] {
    padding: 20px 0px 20px;
  }
}

.errorModal {
  color: red;
  margin-top: 8px;
}

.form_payments {
  width: 98%;

  [class*='paddingCheckbox'] {
    width: 127px !important;
  }
  [class*='tableWithEdit_container'] {
    padding: 20px 0px 20px;
  }
}
