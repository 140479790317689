.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.input {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  .label {
    font-size: 1rem;
    margin-bottom: auto;
    margin-bottom: 1rem;
    line-height: 1.5rem;
  }
  margin-top: 1.5rem;
}
.radioView {
  display: flex;
  height: auto;
  flex-direction: row;
  padding: 0 8px;
  box-sizing: border-box;
}
.formLabel {
  width: 100%;
  font-size: 1rem !important;
  margin-top: 2em;
  margin-bottom: 1.5rem;
  padding-bottom: 10px;
  line-height: 1.5rem;
}
.radioGroup {
  margin-bottom: 5px;
  div:first-child {
    [class*='MuiTypography-body1'] {
      font-size: 1rem;
    }
  }
}
.radioGroupTop {
  margin-bottom: 10px;

  [class*='MuiTypography-body1'] {
    font-size: 1rem;
  }
  div:first-child {
    margin-top: 1.5rem;
  }
  [class*='radioButtons_questionText__gszCd'] {
    line-height: 1.5rem;
  }
}
.radioGroupRow {
  [class*='MuiFormControlLabel-labelPlacementStart'] {
    margin-right: 10px;
  }
  [class*='MuiFormGroup-root'] {
    padding-left: 8px;
    align-items: baseline;
    gap: 3rem;
  }
}
.radioGroupVertically {
  [class*='MuiFormGroup-root'] {
    padding-left: 8px;
  }
}

.input_document {
  padding: 0 4px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  [class*='subtitle_title_fnc'] {
    margin-bottom: 10px;
  }
  .label_document {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

.size_Large {
  width: 100%;
}
.size_Medium {
  width: 49%;
}
.size_Small {
  width: 24%;
}
.container [class*='radioButtons_questionText__gszCd'] {
  font-size: 1rem;
}
