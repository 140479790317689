@use '../../../modules.scss' as *;
@use '../fncInputsSelect.module.scss' as fncInputsSelect;

.root {
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }
}

.textarea {
  @include fncInputsSelect.inputSelect;
  resize: none;

  * + & {
    margin-top: 10px;
  }

  .root.hasError & {
    @include fncInputsSelect.inputSelectBorderError;
  }
}

@include media-breakpoint-down(sm) {
  .textarea {
    @include font($fs: rem(14px), $lh: rem(16px));

    &::placeholder {
      @include font($fs: rem(14px), $lh: rem(16px));
    }
  }
}
