.generalContainer {
  display: flex;
  flex-direction: column;
}

.select_wrapper {
  margin-left: 35px !important;
  min-width: 200px !important;
  align-items: flex-start;
  width: 40%;
}