.wrapper {
  display: flex;
  flex-direction: column;
  padding: 30px 25px 10px;
  min-width: 250px;
  height: 100%;
  a {
    color: rgb(100, 100, 100);
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0px;

    & + a {
      margin-top: 25px;
    }

    svg {
      margin-right: 4px;
    }
  }
}

.avatar {
  margin-bottom: 50px;
  text-align: center;
  .avatarImage {
    width: 100px;
    height: auto;
    border-radius: 50%;
  }
  .avatarAddImage {
    cursor: pointer;
  }
}

.saluteLabel {
  margin-bottom: 20px;
}

.roleLabel {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  svg {
    margin-right: 4px;
  }
}
