@use '../../../modules.scss' as *;

.modalContainer {
  padding: 0px 20px 0px 20px;
  width: 600px;
}

.modalFormContainer {
  display: flex;
  flex-direction: column;
}

.modalRow {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  width: 100%;
  margin-bottom: 20px;

  &.modalRowTwoCol {
    grid-template-columns: 1fr 1fr;

    :first-child {
      justify-self: start;
    }

    :last-child {
      justify-self: end;
      margin: 0;
    }
  }

  &.modalRowFooter {
    :first-child {
      justify-self: end;
    }
  }
}

.fileNameContainer {
  display: grid;
  gap: 5px;
}

.helperText {
  color: $zingPrimaryColor;
  font-size: 14px;
  margin-left: 14px;
  margin-right: 14px;
}
