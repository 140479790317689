@use '../../../../modules.scss' as *;

.title {
  font-size: 15px;
  color: #a4a4a4;
  background-color: #f7f7f7;
  margin: 0;
  padding: 15px 20px 14px;
  text-transform: uppercase;
}

@include media-breakpoint-down(sm) {
  .title {
    @include font($ff: $ff-secondary, $fs: rem(14px), $lh: rem(22px), $fw: 400, $co: $zingSecondaryColor40);
    padding: 14px 20px 14px;
  }
}
