@use '../../../modules.scss' as *;

.error {
  @include font($ff: $ff-primary, $fs: rem(12px), $lh: rem(24px), $co: $primaryColor);
  padding-left: 15px;
}

.containerError {
  label {
    color: $primaryColor;
  }

  .controller {
    margin-bottom: 0;

    fieldset {
      border: 2px solid $primaryColor;
      opacity: .7;
    }
  }
}
