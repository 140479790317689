@use '../../../../modules.scss' as *;

.form {
  width: 100%;
  height: 100%;

  [class*='tableWithEdit_container'] {
    height: auto;
    margin-bottom: 40px;
  }
}

.table {
  margin-bottom: 40px;
}

.compoundItem {
  width: 100%;

  label {
    font-size: 16px;
    line-height: 20px;
    color: $greyFont;
    margin-bottom: 20px;
    margin-left: 0;
  }
  textarea {
    border-radius: 0.5rem;
  }
}

.checkboxWrapper {
  background-color: red;
  margin-bottom: 1rem;
}

.margin_top {
  margin-top: 1rem;
}

.size_50 {
  width: 49%;
}

.size_20 {
  width: 23.5%;

  [class*='MuiInputBase-root'] {
    height: 50px;
  }
  &[class*='MuiFormControl-root'] {
    [class*='MuiInputLabel-marginDense'] {
      transform: translate(14px, 18px) scale(1);
    }
    [class*='MuiInputLabel-marginDense'][class*='MuiInputLabel-shrink'] {
      transform: translate(14px, -6px) scale(0.75);
    }
  }
}

label {
  &.inputLabel {
    display: flex;
    align-items: center;
    margin: 0 0 12px;

    svg {
      margin-right: 4px;
    }
  }
}

.radioButtonsGroup {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.size_100 {
  width: 100%;
}

.addMaterials {
  width: 100%;
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  [class*='buttonGroup_containerLeft'] {
    justify-content: flex-end;
  }
}

// todo: Refactor
.intern_formContainer {
  box-sizing: border-box;
  margin-left: 0 !important;
  width: 65%;
  display: flex;
  justify-content: space-between;
  & > :first-child {
    margin-right: 1.3rem;
  }
  & > :last-child {
    margin-left: 1.3rem;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.state {
  background-color: $zingPrimaryColor80;
  border-radius: 3%;
  margin-top: 2.5%;
}

.state_name {
  color: white;
  margin: 0.5rem 0.4rem;
  font-size: 1em;
}

.format_file {
  //width: 98%;
  color: $greyFont;
  //margin-top: -2%;
  padding: 10px 16px;

  border-left: 6px solid rgba(255, 0, 0, 0.445);
}

.label {
  color: $greyFont;
  width: 100%;
}

.infoChip {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  padding: 19px 14px 18px;
  margin-bottom: 22px;
  border-radius: 4px;
  width: 49%;
  background: #f0f0f0;
  box-sizing: border-box;

  strong {
    color: $greenBubble;
  }

  &.isSuperior {
    strong {
      color: #b50d0d;
    }
  }
}

.infoChipIcon {
  position: absolute;
  top: 16px;
  left: 14px;

  &.isWarning {
    color: #b50d0d;
  }

  &.isCheck {
    color: $greenBubble;
  }
}

.infoChipText {
  font-size: 12px;
  color: $greyFont;
  line-height: 15px;
  font-weight: 400;
  padding-left: 30px;
}

.protectedFormContainer {
  justify-content: flex-start;
  & > :first-child {
    margin-right: 4rem;
  }
}

.wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  * + & {
    margin-top: 60px;
  }
}

.motivational_info {
  color: $greyFont;
  font-size: 0.9rem;
  margin-top: 10px;
}

@include media-breakpoint-down(sm) {
  .compoundItem {
    * + & {
      margin: 20px 0 0;
    }

    & + & {
      margin-top: 40px;
    }

    [class*='MuiInputBase-root'] {
      margin-bottom: 0;
    }

    * + [class*='MuiFormControl-root'] {
      margin-top: 20px;
    }

    [class*='questionText'] {
      @include font($ff: $ff-primary, $fs: rem(14px), $lh: rem(22px), $fw: 400, $co: $grey);
    }

    [class*='MuiFormControlLabel-label'] {
      @include font($ff: $ff-primary, $fs: rem(14px), $lh: rem(22px), $fw: 400, $co: $grey);
    }

    [class*='MuiSvgIcon-root'] {
      width: 12px;
      height: 12px;
    }

    [class*='PrivateSwitchBase'] {
      padding: 5px;
    }

    [class*='radioButtons_options'] {
      margin: {
        right: 15px;
        bottom: 0;
      }
    }

    [class*='MuiFormLabel-root'] {
      @include font($ff: $ff-primary, $fs: rem(14px), $lh: rem(22px), $fw: 400, $co: $grey);
      margin: 0;
    }

  }

  .wrapper {
    [class*='MuiSvgIcon-root'] {
      width: 12px;
      height: 12px;
    }
  }

  .wrapper {
    * + & {
      margin-top: 20px;
    }
  }

  label.label {
    @include font($ff: $ff-primary, $fs: rem(14px), $lh: rem(22px), $fw: 400, $co: $grey);
    margin: 0;

    * + & {
      margin-top: 20px;
    }
  }

  .intern_formContainer {
    * + & {
      margin-top: 8px;
    }
  }

  .form {
    * + & {
      margin-top: 20px;
    }
  }

  .table {
    margin-bottom: 0px;
  }

  .intern_formContainer {
    width: 100%;
    display: block;
  }

  .intern_formContainer {
    & > :last-child {
      margin-left: 0;
    }
  }

  .addMaterials {
    display: block;
  }

  .motivational_info {
    font-size: 0.8rem;
  }
}

@include media-breakpoint-down(sm) {
  .form {
    [class*='tableWithEdit_container'] {
      margin-bottom: 0px;
    }
  }

  .radioButtonsGroup {
    margin-top: 1rem;
  }
}
