@use '../../modules.scss' as *;

.position_logo {
  height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  height: 8vh;
}

.menu {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.root {
  height: 125%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 2em;
}

.wrapper {
  width: 70%;
  margin: 20px auto;
  text-align: center;
  height: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;

  h2 {
    color: $greyFont;
  }

  p {
    a {
      color: #ff5d52;
    }
  }
}

.text {
  margin: 10px;
  color: $greyFont;
}

.docs_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 25%;
  flex-wrap: wrap;
  margin: 30px 0;

  button {
    color: #ff5d52;
    background: transparent;
  }

  button:nth-child(2n) {
    color: #141618;
  }
}

.readMoreButton {
  display: none;
}

@include media-breakpoint-down(sm) {
  .root {
    display: block;
  }

  .wrapper {
    padding: 40px 20px;
    margin: 0;
    width: 100%;
    height: auto;
    display: block;
  }

  .title {
    @include font($ff: $ff-secondary, $fs: rem(20px), $lh: rem(30px), $fw: 400, $co: $greyFont);
    margin: 0;
  }

  .text {
    width: auto;
    @include font($ff: $ff-primary, $fs: rem(14px), $lh: rem(22px), $fw: 400, $co: $greyFont);
    margin: 0;
    word-wrap: break-word;
    
    .title + & {
      margin-top: 20px;
    }

    & + & {
      margin-top: 20px;
    }
  }

  .readMoreWrapper {
    display: none;

    &.isOpen {
      display: block;
      margin-top: 20px;
    }
  }

  .readMoreButton {
    display: block;
    padding: 0;
    border: 0;
    background: transparent;
    margin-top: 10px;
    width: 100%;
  }

  .readMoreText {
    @include font($ff: $ff-primary, $fs: rem(14px), $lh: rem(22px), $fw: 400, $co: $primaryColor);
    border-bottom: 1px solid $primaryColor;
    width: 100%;
    display: block;
    padding-bottom: 6px;
    margin-bottom: 5px;
  }

  svg.readMoreIcon {
    margin: 0 auto;
    display: block;
    width: 12px;
    height: 8px;

    .isOpen & {
      transform: rotate(180deg);
    }
  }
}
