@use '../../../../modules.scss' as *;

.wrapper {
  min-width: 384px;
}

.mainTitle {
  color: $redLightColor;
  font-size: 20px;
  display: flex;
  align-items: center;
  padding: 20px 50px 20px 25px;
  margin: 0;
  position: relative;
  border-bottom: 1px solid #00000010;

  .notification_icon {
    font-size: 16px;
    margin-right: 10px;
  }
  .close_icon {
    color: $redLightColor;
    position: absolute;
    right: 20px;
    top: 18px;
  }
}

.tag {
  background: #e9e9e9;
  text-transform: uppercase;
  font-size: 14px;
  width: fit-content;
  padding: 2px 10px;
  font-size: 0.6vw;
  margin: 0 0 10px;
}

.no_notifications {
  padding: 20px;
  border-bottom: 1px solid #00000010;
}

.pendingWrapper {
  padding: 20px 25px;
  display: block;
  text-decoration: none;
  color: $greyFont;

  & + & {
    border-top: 1px solid #00000010;
  }
}

.see_all {
  color: $redLightColor;
  font-size: 18px;
  padding: 20px 25px;
}
