.form {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-top: 10px;
  :global(.MuiFormControl-root) {
    width: 95%;
    margin: 0;
  }

  .title{
    width: 95%;
    color: #808080;
    padding: 0.5rem 0 1.2rem;
  }

  .paymentsSection{
    display: grid;
    width: 95%;
    grid-template-columns: repeat(3, 1fr);
    justify-content: space-around;
    align-items: flex-start;
    column-gap: 1rem;
    
    .totals > div{
      text-align: center;
    }

  }

  .btn {
    width: 100%;
    margin-top: 1%;
    display: flex;
    justify-content: space-around;
  }
}

