@use 'sass:math';
@use '../../modules.scss' as *;

.container {
  flex: 0 0 30ch;
  width: 30ch;

  .avatarImage {
    position: relative;
    $size: 180px;

    .avatarImage_image {
      display: block;
      border-radius: 50%;
      width: $size;
      height: $size;
      margin: 25px auto;
      border: 1px solid $redLightColor;
      z-index: 0;
    }

    .avatarImage_image:hover + .medioCirculo {
      display: block;
    }

    .medioCirculo {
      display: none;
      position: absolute;
      width: $size;
      height: math.div($size, 2);
      -moz-border-radius: 0 0 100px 100px;
      -webkit-border-radius: 0 0 100px 100px;
      border-radius: 0 0 100px 100px;
      top: 76%;
      left: 50%;
      background-color: rgba(75, 70, 70, 0.5);
      text-align: center;
      transform: translate(-50%, -50%);
      z-index: 1;
    }

    .medioCirculo:hover {
      display: block;
    }

    .buttonImg {
      position: relative;
      height: math.div($size, 6);
      width: math.div($size, 6);
      top: 32%;
      min-width: auto;
      z-index: 1;
      background-color: transparent !important;

      :global(.MuiSvgIcon-root) {
        font-size: 2rem;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .container {
    width: 100%;
    height: 110px;
    margin-bottom: 20px;

    .avatarImage {
      $size: 100px;
      margin-bottom: 20px;
      height: $size;

      .avatarImage_image {
        width: $size;
        height: $size;
        border: none;
      }

      .buttonImg {
        position: absolute;
        height: 21px;
        width: 25px;
        top: 30%;
        left: 38%;
        z-index: -1;
      }
    }
  }

  .medioCirculo {
    display: block !important;
    position: absolute;
    width: 100px !important;
    height: 50px !important;
    -moz-border-radius: 0 0 100px 100px;
    -webkit-border-radius: 0 0 100px 100px;
    border-radius: 0 0 100px 100px;
    top: 85% !important;
    left: 50%;
    background-color: rgba(75, 70, 70, 0.5);
    text-align: center;
    transform: translate(-50%, -50%);
  }

  .avatarImage_image {
    position: absolute;
    display: block;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    margin: 0 !important;
    border: 1px solid $redLightColor;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .imageUserTitle {
    font-family: 'Odudo';
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 18px;
    color: $primaryColor;
  }
}
