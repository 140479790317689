@use '../../modules.scss' as *;

.root {
  border: 0;
  padding: 0;
  display: flex;
  align-items: center;
}

.text {
  @include font($ff: $ff-primary, $fs: rem(12px), $lh: rem(18px), $fw: 400, $co: $primaryColor);
  text-transform: uppercase;

  .icon + & {
    margin-left: 8px;
  }
}

.icon {
  svg {
    display: block;
  }
}

@include media-breakpoint-down(sm) {
  .root {
    background-color: transparent;
  }
}
