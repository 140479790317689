@use '../../../../modules.scss' as *;

.containerWithMenu {
  display: flex;

  .container {
    margin: auto;
    padding-top: 20px;
  }

  .menuRight {
    width: 15vw;
    background: white;
    border-left: 1px solid rgba(0, 0, 0, 0.12);
    position: fixed;
    top: 10.5vh;
    height: 80%;
    right: 0;
  }
}

.title_textInput {
  color: $greyFont;
  margin-bottom: 20px;
  width: 100%;
}

.totalTitle {
  color: $greyFont;
  width: 100%;
  margin-bottom: 10px;
  margin-top: 20px;
}

.total {
  font-weight: bold;
  color: $greyFont;
  margin-bottom: 25px;
}

.mentorSection {
  width: 80%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
