.container {
  width: 100%;
  margin-top: 1rem;
  .formModalContainer {
    display: flex;
    flex-direction: column;
    .subContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0.5rem;
      .textarea{
        width: 100%;
      }
      .float_20{
        width: 19.5%;
      }
      .float_30{
        width: 29.5%;
      }
    }
  }

  .buttons {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-bottom: 0.5rem;
  }

  .size_50 {
    width: 49%;
  }
}
