@use '../../../modules.scss' as *;

.root {
  * + & {
    margin-top: 20px;
  }
}

.search {
  height: 50px;
  width: 100%;
  align-items: stretch;
  flex: 0 0 100%;
  background-color: #ECECEC;
  display: flex;
  border: 0;
  border-radius: 4px;
  position: relative;

  [class*='fncInputText_root'] {
    width: 100%;
  }

  input {
    height: 100%;
    border: none;
    background-color: transparent;
    padding-right: 40px;
  }

  button[type="submit"] {
    position: absolute;
    right: 0;
    text-indent: -999px;
    overflow: hidden;
    width: 40px;
    padding: 0;
    border: 1px solid transparent;
    border-radius: inherit;
    background: transparent url(../../../images/icons/iconSearch.svg) no-repeat;
    cursor: pointer;
    opacity: 0.7;
    margin-left: auto;
    top: 15px;
    pointer-events: none;
  }

  button[type="submit"]:hover {
    opacity: 1;
  }
}