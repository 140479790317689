@use '../../../modules.scss' as *;

.container {
  width: 800px;
  padding: 10px;
}
.newcontact {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.btn_create {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
