@use '../../../../../../modules.scss' as *;

.title {
  font-size: 25px;
  font-weight: normal;
  margin: 0;
  color: $primaryColor;
}

[class*='MuiTableCell-paddingCheckbox'] {
  width: 150px !important;
}
