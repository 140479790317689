@use '../../../../modules.scss' as *;

p {
  margin: 0;
}

.title {
  color: $primaryColor80;
  font-size: 24px;
  background: rgba(102, 102, 102, 0.1);
  padding: 20px 30px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;

  :global(.MuiDialogTitle-root) {
    padding: 0;
    display: flex;

    h2 {
      line-height: 1;
      font-weight: 600;
      padding: 0;
    }
  }
}

.dialogContent {
  padding: 25px 30px 40px;
  width: 800px;
}

.form {
  padding: 0 20px 0 20px;

  > .content {
    display: grid;
    grid-template-columns: auto;
    grid-gap: 20px;
  }

  :global(.MuiFormControl-root) {
    width: auto;
    flex-grow: 1;
    margin: 0;
  }

  [class*='keyboardDatePickerController_size'] {
    width: 50%;
  }

  [class*='formContainer_root'] {
    margin-top: 0;
    margin-bottom: 10px;
  }

  [class*='MuiInputBase-root'] {
    margin: 0;
  }
}

.selectors {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  &__sub {
    display: flex;
  }
}

.btn_create {
  display: flex;
  justify-content: flex-end;
  [class*='MuiButtonBase'] {
    width: 20%;
    margin-left: 10px;
  }
}

.combo_style {
  [class*='comboSearch_comboContainerFull'] {
    width: 97%;
    margin-left: 10px;
  }
}

.template_size {
  [class*='selectInput_size_50'] {
    width: 50% !important;
  }
}

@include media-breakpoint-down(sm) {
  .form {
    padding: 0;

    > .content {
      grid-gap: 20px;
    }

    [class*='tableWithEdit_container'] {
      display: none;
    }

    > .submenuInternalProjects {
      margin-top: 20px;
      height: 50px;
    }

    .selectors {
      grid-gap: 15px;
      grid-template-columns: 1fr;
    }

    [class*='formContainer_root'] {
      margin-bottom: 15px;
    }

    [class*='keyboardDatePickerController_size'] {
      width: 100%;
    }

    [class*='FncAccordion_root'] {
      margin: 0;
      .selectors {
        margin: 0;
        padding: 20px;
        background-color: #ececec;
      }

      [class*='MuiFormControl-root'] {
        background-color: white;
      }
    }
  }

  .dialogContent {
    width: auto;
  }

  .containerAddDocumentBtn {
    display: none;
    margin-top: 20px;
  }

}
