.tableContainer {
  width: 100%;
  padding: 20px 0 0 0;

  [class*='paddingCheckbox'] {
    width: 127px !important;
  }
  [class*='tableWithEdit_container'] {
    padding: 20px 0;
  }
}
