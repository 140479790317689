@use '../../../modules.scss' as *;

.title {
  color: $primaryColor80;
  font-size: 24px;
  background: rgba(102, 102, 102, 0.1);
  padding: 20px 30px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;

  :global(.MuiDialogTitle-root) {
    padding: 0;
    display: flex;

    h2 {
      line-height: 1;
      font-weight: 600;
      padding: 0px;
    }
  }
}

.dialogContent {
  padding: 0px !important;
  width: 900px;
  height: 500px;

  [class*='loading_wrapper'] {
    margin: 15% 0;
  }

  p {
    margin: 0;
  }
}

.bodyContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 25px 30px 40px;
}

.btn_create {
  display: flex;
  justify-content: flex-end;
  width: 97%;

  [class*='MuiButtonBase-root'] {
    margin: 0 10px 20px;
  }
}

@include media-breakpoint-down(sm) {
  .dialogContent {
    width: auto;
  }
}
