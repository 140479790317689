@use '../../modules.scss' as *;

.containerList {
  .title {
    color: $redLightColor;
  }
  .titleContent {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .titleButtons {
    display: flex;
    justify-content: flex-end;
    .buttons {
      padding: 0px !important;
      min-width: 35px !important;
    }
  }
  .list {
    margin-bottom: 2.5rem;
    min-width: 340px;
    display: flex;
    flex-direction: column;

    .addContainer {
      display: flex;
      justify-content: space-between;
      padding: 20px 0px;
      align-items: center;
    }

    .textfield {
      height: 51px;
      width: 270px;
      box-sizing: border-box;
    }

    .containerItem {
      display: flex;
      justify-content: space-between;
      min-width: 410px;

      .active {
        border: 1px solid $greyFont;
        width: 370px;
      }

      .rootText {
        width: 270px;

        fieldset {
          border: none;
        }
      }

      .iconGroup {
        height: 50px;
        padding: 2px;
      }

      button {
        align-self: center;
      }
    }
  }
}
.codeContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  .generalContainer {
    color: #666666;
  }
}
[class*='MuiDialogActions-root MuiDialogActions-spacing'] {
  padding: 12px 24px !important;
}

.noticeContainer {
  padding: 20px 0 8px !important;
  color: #666666;
}
.noticeQuestion {
  margin: 1.5rem 0;
}
