@use '../../../modules.scss' as *;

.panel_detail_row {
  display: flex;
  border-bottom: 1px solid #e0e0e0;
  font-size: 0.875rem;
  color: $greyFont;
  .space_reserved_space_buttons {
    width: 58px; // width of arrow + checkbox
  }
  div {
    padding: 16px;
    width: 25%;
  }
}

.panel_detail_row:last-child {
  border-bottom: none;
}

.container {
  //    padding-left: 12px;
  [class*='title_title'] {
    padding-left: 12px;
  }
  [class*='inputs_size_20'] {
    margin-right: 16px !important;
  }
  [class*='selectInput_size_20'] {
    margin-right: 16px !important;
  }
  [class*='MuiTable-root'] {
    table-layout: auto !important;
  }
  [class*='tableWithEdit_container'] {
    padding: 20px 10px;
  }
}
