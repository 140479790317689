@use '../../modules.scss' as *;

.root {
  display: flex;
  padding: 5px 0;
  margin-bottom: 30px;
  align-items: center;
  .icon {
    color: $redLightColor;
    opacity: 0.8;
  }
}

.message {
  margin-left: 10px;
  color: #666666;

  * + & {
    margin-left: 10px;
  }
}

.message_raw {
  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__text {
    margin-left: 5px;
  }
}

@include media-breakpoint-down(sm) {
  .root {
    padding: 0;
    margin: 0;

    * + & {
      margin-top: 20px;
    }
  }
  .icon {
    width: 24px;
    height: 24px;
  }

  .message {
    @include font($ff: $ff-secondary, $fs: rem(12px), $lh: rem(18px), $fw: 400, $co: $greyFont);
  }
}
