@use '../../modules.scss' as *;

.parraf {
  font-size: 1.5em;
  color: $greyFont;
  margin-top: 2%;
  .access_fnc {
    color: $primaryColor;
    cursor: pointer;
  }
  .access_zing {
    color: $zingPrimaryColor;
    cursor: pointer;
  }
}

.container {
  margin-top: -6%;
  color: $greyFont;
  .access_fnc {
    color: $primaryColor;
    cursor: pointer;
  }
  .access_zing {
    color: $zingPrimaryColor;
    cursor: pointer;
  }
}

.rowComboSearch {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 22px;
  [class*='comboContainer'] {
    width: 100%;
    [class*='MuiFormLabel-filled'] {
      background-color: #fafafa;
      padding: 0 3px;
    }
  }
}

.menuSelect {
  height: 240px;
}

.format_file {
  width: 95%;
  color: $greyFont;
  padding: 10px 1rem;
  margin-bottom: 1rem;
  border-left: 6px solid rgba(255, 0, 0, 0.445);
}

.modalHelp {
  display: flex;
  justify-content: center;
  margin: 4%;
}

@include media-breakpoint-down(sm) {
  .rowComboSearch {
    display: block;
    width: 100%;

    * + & {
      margin-top: 14px;
    }
  }
}
