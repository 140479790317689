.pending_tasks_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 20px 40px;
  a {
    color: black;
    text-decoration: none;
  }
}

.avatar_wrapper {
  display: flex;
  align-items: center;
  img {
    margin-right: 4px;
  }
}

.buttons{
  position: absolute;
  right: 0;
  svg{
    cursor: pointer;
  }
}

.navList{
  width: 100%;
}
