@use '../../../../../modules.scss' as *;

.minimalInfo {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  gap: 5%;

  .item1,
  .item2 {
    display: flex;
    flex-direction: column;
  }
}

[class*='tableWithEdit_container'] {
  height: auto !important;
  padding-bottom: 3rem;
}
