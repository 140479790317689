@use '../../../../../../modules.scss' as *;

.title {
  font-size: 25px;
  font-weight: normal;
  margin: 0px 0px;
  color: $primaryColor;
}

.select_role {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: baseline;
  gap: 10px;
  margin: 1rem 0;

  [class*='MuiButton-outlinedPrimary'] {
    align-self: center;
  }
}

.select_wrapper {
  width: 47.5% !important;
}

[class*='tableWithEdit_container'] {
  height: 40%;
}
