@use '../../modules.scss' as *;

.buttonUpload, .chip {
  width: 200px;
  min-width: 200px;
}

.fileNameContainer {
  display: grid;
  gap: 5px;
}

.helperText {
  color: $zingPrimaryColor;
  font-size: 14px;
  margin-left: 14px;
  margin-right: 14px;
}
