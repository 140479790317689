@use '../../modules.scss' as *;

.title {
  color: $redLightColor !important;
  font-size: 24px;
  background: rgba(102, 102, 102, 0.1);
  padding: 20px 30px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;

  :global(.MuiDialogTitle-root) {
    padding: 0px;
    display: flex;

    h2 {
      line-height: 1;
      font-weight: 600;
    }
  }
}

.titleRed h2 {
  color: $redLightColor !important;
}

.content {
  padding: 15px !important;
}

.actions {
  @extend .content;
}
