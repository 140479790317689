@use '../../modules.scss' as *;

.panel {
  margin: 0 20px !important;
  h3 {
    color: $zingPrimaryColor;
    font-size: 2rem;
    font-weight: 500;
    line-height: 1.6;
    margin: 0;
  }
}

.panel_details {
  flex-direction: column;
  align-items: center;
}

.legend_wrapper {
  margin: 0;
  min-width: 300px;
  line-height: 1.5em;
  li {
    cursor: pointer;
  }
}

.bold {
  font-weight: bold;
}

.legend_color {
  height: 15px;
  width: 15px;
  margin-right: 6px;
}

.legend_item {
  display: flex;
  align-items: center;
}

.tooltip_wrapper {
  background-color: white;
  padding: 8px;
  border-radius: 5px;
}

.see_all {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.select_dashboard {
  display: flex;
  width: 100%;
}

.select_wrapper {
  min-width: 150px !important;
  align-items: flex-start;
  width: 30%;
}

@include media-breakpoint-down(sm) {
  .panel {
    display: none;
  }
}
