@use '../../../modules.scss' as *;

.forgot_form_fnc {
  margin: 20px 0;

  .resend {
    margin-bottom: 15px;
    margin-left: 100%;
    transform: translateX(-100%);
  }

  .text {
    margin-bottom: 15px;
  }

  .mail {
    font-weight: bold;
  }
}

.forgot_form_zing {
  @extend .forgot_form_fnc;
  color: white;

  .text {
    margin-bottom: 15px;
  }

  .mail {
    font-weight: bold;
  }

  [class*='MuiAlertTitle-root'] {
    color: #611a15;
  }
}

.errorMsg {
  margin: 20px 0;
}
