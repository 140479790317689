@use '../../modules.scss' as *;

%title {
  display: flex;
  justify-content: center;
  font-size: 2em;
  font-weight: 500;
  letter-spacing: 0.1em;
  margin-top: 4em;
}

.title_fnc {
  @extend %title;
  color: $primaryColor;
}

.title_zing {
  @extend %title;
  color: $zingPrimaryColor;
}
