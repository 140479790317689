.container {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 13%;
}

.message {
	font-size: 1.2rem;
	font-weight: 400;
	color: #666666;
	padding: 1.5rem;
}
