@use '../../modules.scss' as *;

.checkContainer {
  width: auto;
}
.checkForm {
  width: 49%;
}

.checkNoWidth {
  width: auto;
}
.label {
  color: $greyFont;
}
