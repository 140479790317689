@use '../../../../modules.scss' as *;

.familyIncomeTableContainer {
  width: 100%;
}

.tableTitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.addNewFamilyIncome {
  text-align: right;
}

.familyIncomeRow {
  border: 1px solid  $secondaryColor20;
}


.familyIncomeTableRow {
  @extend .familyIncomeRow;
}

.familyIncomeTableRow:hover {
  background-color: rgba(0, 0, 0, 0.04);
  transition: 0.9s;
}

.familyIncomeTableHeader {
  @extend .familyIncomeRow;
  border: 1px solid  $secondaryColor20;
}

.familyIncomeTable {
  border-collapse: collapse;
  border: 1px solid  $secondaryColor20;
  margin: 20px 0px;
  font-size: 14px;
  width: inherit;
  background-color: white;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  box-shadow: 0px 1px 1px 0px $secondaryColor40;
}


.familyIncomeCellHeader {
  padding: 20px;
  text-align: left;
  color: $secondaryColor80;
}

.familyIncomeCellRow {
  padding: 10px;
  text-align: left;
}

.incomeTypeCell {
  width: 60%;
  text-align: left;
}

.familyMemberCell {
  width: 40%;
  text-align: left;
}

.amountCell {
  width: 100px;
  text-align: right;
}

.actionCell {
  display: flex;
  margin-right: 5px;
}

.familyIncomeEmptyCellRow {
  padding: 20px;
  text-align: center;
}

.tableMessageArea {
  padding-bottom: 20px;
}

.familyIncomeError {
  color: $redLightColor;
  font-size: 0.9rem;
}

@include media-breakpoint-down(sm) {
  .familyIncomeTable {
    font-size: 0.8rem;
  }
  .actionCell {
    width: 50px;
  }
 }
