@use '../../../modules.scss' as *;

.wrapper {
  background-color: white;
  padding: 0px;
  margin: 0 20px;
}

.tabs {
  width: 100%;
}

.tab_selected {
  background: $primaryColor80 !important;
  color: white !important;
}

.tab_unselected {
  background: white !important;
}

.tabPanels {
  border-top: 0.2px solid $secondaryColor20;
  height: 630px;
}
