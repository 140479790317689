@use '../../../modules.scss' as *;

.root {
  display: none;

  .borderRed {
    border-color: $zingPrimaryColor80;
  }

  .borderGreen {
    border-color: $greenBubble;
  }

  .borderYellow {
    border-color: $orangeBubble;
  }
}

hr {
  width: 100%;
  margin: 25px 0;
}

.icon {
  svg {
    display: none;
  }
}

@include media-breakpoint-down(sm) {
  .root {
    display: block;
    padding: 20px;

    * + & {
      border-top: 1px solid $greyLight;
    }
  }

  .title {
    @include font($ff: $ff-primary, $fs: rem(24px), $lh: rem(28px), $co: $primaryColor);
  }

  .list {
    display: flex;
    flex-wrap: wrap;
    gap: 25px 20px;
    justify-content: center;

    * + & {
      margin-top: 20px;
    }
  }

  .item {
    width: 150px;
    height: 150px;
    padding: 20px 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid $greyLight;
    border-radius: 4px;
  }

  .icon {
    svg {
      width: 54px;
      height: 48px;
      fill: $greyLight;
      display: inline-block;
      font-size: 1.5rem;
      transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      flex-shrink: 0;
      user-select: none;
    }
  }

  .itemTitle {
    @include font($ff: $ff-secondary, $fs: rem(14px), $lh: rem(20px), $co: $grey);
    text-align: center;

    * + & {
      margin-top: 17px;
    }
  }
}
