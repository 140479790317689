@use '../modules.scss' as *;

.required fieldset {
  border: 2px solid $redLightColor !important;
}

.required_50 {
  width: 49%;
  fieldset {
    border: 2px solid $redLightColor !important;
  }
}

.required_20 {
  width: 23.5%;
  fieldset {
    border: 2px solid $redLightColor !important;
  }

  [class*='MuiInputBase-root'] {
    height: 50px;
  }
  &[class*='MuiFormControl-root'] {
    [class*='MuiInputLabel-marginDense'] {
      transform: translate(14px, 18px) scale(1);
    }
    [class*='MuiInputLabel-marginDense'][class*='MuiInputLabel-shrink'] {
      transform: translate(14px, -6px) scale(0.75);
    }
  }
}

.requiredRadio {
  color: $redLightColor !important;
  vertical-align: text-top;
  margin-right: 5px;
}

.requiredCheckbox {
  color: $redLightColor !important;
  font-size: 30px;
  vertical-align: middle;
}

.requiredAutocomplete {
  border: 2px solid rgba(254, 93, 82, 0.3) !important;
  height: 52px !important;

  & fieldset {
    border: none !important;
  }
}

.green fieldset {
  border: 2px solid green !important;
}
