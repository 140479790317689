@use '../../modules.scss' as *;

.format {
  height: 100%;
  font-size: 16px;
  text-align: center;
  margin-bottom: 15px;
  border: 1px solid rgba(0, 0, 0, 0.054);
  border-radius: 0;
  border-style: groove;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 15px;
  svg {
    margin-right: 10px;
  }
}

.format_button {
  @extend .format;
  cursor: pointer;
}

@include media-breakpoint-down(sm) {
  .format {
    padding: 15px 10px;
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
  }
}
