.activeCheckBox {
    display: flex;

  }

  .inactiveTitle{
    color: #666666;
    font-size: 1rem;
    font-family: Fira Sans,"Segoe UI Symbol";
    font-weight: 400;
    line-height: 1.5;
    padding: 7px;
    margin-right: 3px;
  }

.inactiveTitleDisabled {
  @extend .inactiveTitle;
      color: rgba(0, 0, 0, 0.38);
}