.selectIdiomaContainer {
  width: 100%;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  fieldset {
    height: 50px;
  }
}

.idioma {
  color: #666666;
}

h1,
h2 {
  margin-left: 0px !important;
}

.subjectContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #666666 !important;
  margin-bottom: 40px;

  .subjectSpanish {
    flex: 0 0 35%;
    input {
      width: 100%;
      height: 40px;
      color: #666666;
      font-size: 14px !important;
      padding-left: 20px;
      box-sizing: border-box;
    }
  }

  .subjectLang {
    flex: 0 0 62%;
    input {
      width: 100%;
      height: 40px;
      color: #666666;
      font-size: 14px !important;
      padding-left: 20px;
      box-sizing: border-box;
    }
  }
}

.containerTextArea {
  display: flex;
  flex-direction: column;
}
.contentEs {
  flex: 0 0 35%;
  border: 1px solid rgba(118, 118, 118, 0.3);
  box-sizing: border-box;
  padding: 20px;
  color: #666666;
  font-size: 14px !important;
}

.containerTextAreaCol {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}

.labelsContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  .esLabel {
    color: #666666;
    flex: 0 0 35%;
  }

  .langLabel {
    color: #666666;
    flex: 0 0 62%;
  }
}
