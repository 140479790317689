@use '../../../modules.scss' as *;

.tableMessageArea {
	padding-bottom: 20px;
	font-size: 0.9rem;
}

.tableErrorMessage {
	color: $redLightColor;
	
}