@use '../../modules.scss' as *;

.avatarMaxWidth {
  max-width: 40%;
}

.crop_image_container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.crop_file_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 0.1rem;
  height: 100%;
}

.crop_icon_close {
  width: 5%;
  height: 5%;
  font-family: 'Open Sans', sans-serif;
  border: none;
  margin: 0.7rem;
  margin-left: 90%;
  cursor: pointer;
  text-align: center;
}

.buttonMargin {
  padding-top: 40px;
}

.crop_icon_close:hover {
  background-color: rgb(197, 193, 193);
}

.crop_file[type='file'] {
  display: none;
}
.crop_file_label {
  margin-top: 0.5rem;
  margin-bottom: 0.7rem;
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}

.crop_image_position {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.crop_result {
  min-width: 100px;
  min-height: 100px;
}

.crop_image_result {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.crop_image_end {
  border-radius: 50%;
  max-width: 125px;
  max-height: 125px;
  border: 1px solid gray;
}

.crop_button {
  margin-top: 1rem;
  cursor: pointer;
  text-align: center;
  border: none;
  background-color: #ccc;
  padding: 6px 12px;
  order: 2;
}

.crop_button:hover {
  background-color: rgb(197, 193, 193);
}

.crop_image_view {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100%;
}

.crop_select {
  margin-right: 2rem;
}

.crop_select div img {
  max-width: 300px;
  max-height: 200px;
}

.crop_image {
  margin-bottom: 0.7rem;
  height: 150px;
  width: 150px;
  border-radius: 50%;
  border: 1px solid gray;
}

.crop_image_imgRound {
  @extend .crop_image;
  height: 150px;
  width: 150px;
  border-radius: 50%;
  border: 1px solid gray;
}
.avatar-component {
  padding-bottom: 20px;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.titleText {
  font-weight: bold;
  color: black;
}

.crop-tool {
  display: flex;
  justify-content: center;
}

.cropping-box {
  width: auto;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.cropped-box {
  width: 200px;
  height: auto;
  border-radius: 50%;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.picture-render-component {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
}

.max-size-text {
  color: gray;
  font-size: 12px;
}

.new-pic-display-container,
.profile-pic {
  width: 175px;
  height: auto;
  text-align: center;
}

.input_file_button {
  font-family: 'Open Sans', sans-serif;
  color: gray;
  background-color: #f1f1f1;
  border: 1px dashed #a9a9a9;
  border-radius: 20px;
  text-transform: uppercase;
  padding: 4px 14px;
  cursor: pointer;
  :focus {
    outline: none;
  }
}
@include media-breakpoint-down(sm) {
  .avatarMaxWidth {
    max-width: none;
    height: 180px;
    width: 180px;
  }

  .crop_select {
    margin: 0px 0px 20px 0px;
  }

  .crop_select div img {
    max-width: 420px;
    max-height: 320px;
  }

  .buttonMargin {
    width: 100%;
  }

  .crop_image_position {
    height: auto !important;
    margin-top: 30px;
  }

  .crop_image_container {
    height: auto !important;
    margin-top: 20px;
  }
}
