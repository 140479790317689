@use '../../../../../../modules.scss' as *;

.title {
  color: $primaryColor80;
  font-size: 24px;
  background: rgba(102, 102, 102, 0.1);
  padding: 20px 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;

  :global(.MuiDialogTitle-root) {
    padding: 0;
    display: flex;

    h2 {
      line-height: 1;
      font-weight: 600;
      padding: 0px;
    }
  }
}

.dialogContent {
  padding: 20px !important;
  width: 900px;
  height: 700px;

  .form {
    width: 100%;
  }
}

.select_role {
  width: 100% !important;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  height: 40px;
  gap: 10px;
  margin: 1rem 0;

  .select_edition {
    [class*='MuiTypography-body'] {
      width: max-content;
    }
  }

  [class*='MuiButton-outlinedPrimary'] {
    align-self: center;
  }
}

.select_wrapper {
  width: 85%;
}

.btn_create {
  display: flex;
  justify-content: flex-end;
  width: inherit;
  position: absolute;
  right: 0;
  bottom: 0;
  margin: 3%;

  [class*='MuiButtonBase'] {
    width: 20%;
    margin-left: 10px;
  }
}

.table_role {
  padding: 0px;

  [class*='tableWithEdit_customContainer'] {
    border: solid 0.1px rgba(0, 0, 0, 0.12);
  }

  [class*='MuiTableCell-head'] {
    padding: 10px 25px !important;
  }

  [class*='MuiTableCell-root'] {
    padding: 0px 25px;
  }
}

@include media-breakpoint-down(sm) {
  .dialogContent {
    width: auto;
  }
}