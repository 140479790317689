@use '../../modules.scss' as *;

.change_password_ok_fnc {
  a {
    display: block;
    margin-top: 20px;
    color: $redLightColor;
    font-size: 15px;
    line-height: 12px;
    text-decoration: none;
  }
}

.change_password_ok_zing {
  color: white;
  a {
    display: block;
    margin-top: 20px;
    color: $redLightColor;
    font-size: 15px;
    line-height: 12px;
    text-decoration: none;
  }
}

.languageContainer {
  margin-top: 3rem;
}
