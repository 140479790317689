@use '../../../modules.scss' as *;

.parrafo {
    padding-left: 10px;
    color: $greyFont;
    width: 100%;
    margin-bottom: 20px;
    text-align: justify;
    a {
      color: $primaryColor;
      text-decoration: none;
    }
  }