@use '../../../../modules.scss' as *;

p {
  margin: 0;
}

.title {
  color: $redLightColor !important;
  font-size: 24px;
  background: rgba(102, 102, 102, 0.1);
  padding: 20px 30px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;

  :global(.MuiDialogTitle-root) {
    padding: 0px;
    display: flex;
    width: 100%;

    h2 {
      line-height: 1;
      font-weight: 600;
    }
  }
}

.titleRed h2 {
  color: $redLightColor !important;
  line-height: 1;
  font-weight: 600;
  font-size: 1.25rem;
  margin: 0;
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dialogForm {
  :global(.MuiDialog-paperWidthSm) {
    min-width: 40%;
    height: 80%;
    max-width: fit-content;
  }
  [class*='MuiTableCell-root'] {
    padding: 15px 15px;
    font-size: 0.75em;
  }
}

.dialogInfo {
  padding-left: 40px !important;
  display: flex;
  flex-direction: column;
  overflow: initial !important;
}

.button2 {
  width: 40%;
  height: 100%;
  border: none;
  background-color: rgb(180, 172, 172);
}

.button {
  width: 20%;
  height: 10%;
  border: none;
  background-color: rgb(180, 172, 172);
}

.button:hover {
  background-color: rgb(151, 146, 146);
}

.button2:hover {
  background-color: rgb(151, 146, 146);
}

.position {
  display: flex;
  justify-content: space-around;
  width: 100%;
  height: 10%;
}

[class*='MuiDialogActions-root'] {
  padding: 20px 20px 12px 0px !important;
}
