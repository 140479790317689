@use '../../modules.scss' as *;

.imageGalleryContainer {
  display: flex;
  padding: 20px 0;
  position: relative;

  [class*='swiper'] {
    width: 900px;
  }

  [class*='swiper-container'] {
    height: 20rem;
    width: 100%;

    @media screen and (max-width: 32rem) {
      height: 10rem !important;
    }
  }

  [class*='swiper-slide'] {
    display: flex;
    flex-direction: column;
    height: auto;
    max-height: 20rem;
    align-items: flex-start;
    color: #fff;
    justify-content: center;
    font-size: 1.5rem;

    .title {
      color: #021129;
      padding-bottom: 10px;
      margin: auto;
      font-size: 1rem;
    }

    .description {
      color: #021129;
      padding-top: 10px;
      padding-left: 25px;
      font-size: 0.8rem;
    }

    .image {
      display: block;
      width: 100%;
      height: 80%;
      object-fit: cover;
      cursor: pointer;
    }
  }

  [class*='swiper-button-prev'],
  [class*='swiper-button-next'] {
    width: auto;
    padding: 0 20px;
  }

  [class*='swiper-button-prev swiper-button-disabled'],
  [class*='swiper-button-next swiper-button-disabled'] {
    opacity: 0 !important;
  }
}

.editImageSection {
  border-radius: 30px;
  background-color: #fff;
  box-shadow: 0px 0px 10px 3px #da291c;
  z-index: 2;

  position: absolute;
  right: 10%;

  &:after {
    color: #333333;
  }

  &:hover {
    background-color: #dad9e8;
  }
}
