@use '../../modules.scss' as *;

.buttonUpload {
  width: 200px;
}

.fileNameContainer {
  display: grid;
  gap: 5px;
}

.helperText {
  color: $zingPrimaryColor;
  font-size: 14px;
  margin-left: 14px;
  margin-right: 14px;
}

.chip {
  svg {
    margin-left: 10px;
  }
}
