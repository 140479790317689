@use '../../modules.scss' as *;

.root {
  display: flex;
  flex-direction: column;
}

.title {
  margin-bottom: 20px;
}

.titleText {
  @include font($ff: $ff-primary, $fs: rem(20px), $lh: rem(24px), $fw: 400, $co: $primaryColor);
  margin: 0;

  .root .root & {
    color: $greyFont;
  }
}

.icon {
  display: none;
}

.content {
  height: 100%;
}

.animationWrapper {
  height: 100%;
}

@include media-breakpoint-down(sm) {
  .root {
    * + & {
      margin-top: 20px;
    }
  }

  .title {
    position: relative;
    padding: 15px;
    margin: 0;
    background-color: $primaryColor;

    .root .root & {
      background-color: $greyLight;
    }
  }

  .titleText {
    @include font($ff: $ff-secondary, $fs: rem(16px), $lh: rem(18px), $fw: 400, $co: $white);

    .root .root & {
      color: $secondaryColor;
    }
  }

  .icon {
    position: absolute;
    top: 21px;
    right: 15px;
    display: block;
    fill: $white;
    transition: transform 0.3s ease-in;

    .root.isClose & {
      transform: rotate(180deg);
    }
  }

  .content {
    display: grid;
    grid-template-rows: 1fr;
    transition: all 0.3s ease-in;

    .root.isClose & {
      grid-template-rows: 0fr;
    }
  }

  .animationWrapper {
    overflow: hidden;
    padding-top: 10px;
  }
}
