@use '../../../modules.scss' as *;

.containerAbout {
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
  h3 {
    margin: 0;
  }
}
.expansionPanelDetails {
  flex-direction: column;
}

.resource {
  width: 100%;
}

.file {
  display: flex;
  padding: 4px 35px;
  div {
    width: 25%;
  }
}

.download_button {
  text-align: end;
}

.isGray {
  color: $greyFont !important;
}

.list {
  background-color: $white;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  padding: 20px 20px;
}

.item {
  list-style: none;
}

.wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr auto;
  padding: 8px 35px;
}

.title {
  @include font($ff: $ff-secondary, $fs: rem(18px), $lh: rem(22px), $fw: 400, $co: $greyFont);
  padding: 8px 0;
}

.itemText {
  @include font($ff: $ff-primary, $fs: rem(16px), $lh: rem(20px), $fw: 400, $co: $greyFont);
  padding: 8px 0;
  align-self: center;
}

.button {
  border: 0;
  background: transparent;
  padding: 0;
}

.icon {
  display: block;
  fill: $greyT;
}

.message {
  padding: 8px 35px;
}

@include media-breakpoint-down(sm) {
  .list {
    padding: 0;
    background: transparent;
    box-shadow: 0;
  }

  .item {
    padding: 20px 0px;

    & + & {
      border-top: 1px solid $greyLight;
      padding-top: 30px;
    }
  }

  .title {
    @include font($fs: rem(16px), $lh: rem(20px));
    padding: 0;
  }

  .wrapper {
    grid-template-columns: 1fr auto;
    padding: 0;

    & + & {
      margin-top: 15px;
      padding-top: 15px;
      border-top: 1px solid $greyLight;
    }

    .title + & {
      margin-top: 15px;
    }
  }

  .itemText {
    @include font($fs: rem(14px), $lh: rem(18px));
    padding: 0;
  }

  .uploader {
    grid-row: 2;
    grid-column: 1;
  }

  .update {
    grid-row: 3;
    grid-column: 1;
  }

  .buttonWrapper {
    //grid-column: 2 / span 3;
  }
}
