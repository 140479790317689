@use '../../modules.scss' as *;

.container {
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
  h1 {
    margin-bottom: 20px;
  }
  hr {
    height: 1px;
    opacity: 0.27;
    background-color: $greyFont;
    margin-top: 20px;
  }
}

.search_subtitle {
  color: $greyFont;
  font-size: 1.3vw;
  span {
    color: #fe655a;
  }
}
