@use '../../../modules.scss' as *;

.mainContainer {
  display: flex;
  justify-content: space-between;

  .loading {
    margin: auto;
  }

  .containerDraggableElement {
    position: relative;

    .leftContainer {
      flex: 1 0 90%;
    }

    .rightContainer {
      position: absolute;
      top: 0;
      right: 0;
    }

    .rightContainer_fnc {
      @extend .rightContainer;

      svg {
        color: $secondaryColor80;
      }
    }

    .rightContainer_zing {
      @extend .rightContainer;

      svg {
        color: $zingSecondaryColor80;
      }
    }
  }

  .listFields {
    width: 48%;
    padding: 8px;

    [data-rbd-droppable-id] {
      height: 55vh;
      border: 1px solid lightgrey;

      padding: 8px;
      border-radius: 4px;
      background: white;
      overflow-y: auto;
      overflow-x: hidden;
    }

    .titleFieldList {
      margin-bottom: 10px;
      font-size: 20px;
      font-weight: bold;
    }

    .titleFieldList_fnc {
      @extend .titleFieldList;
      color: $secondaryColor;
      columns: $secondaryColor80;
    }

    .titleFieldList_zing {
      @extend .titleFieldList;
      color: $zingSecondaryColor;
      columns: $zingSecondaryColor80;
    }

    .field {
      padding: 14px 8px 20px;
      border-radius: 0 4px 4px 0;
      margin-bottom: 12px;
      display: flex;
      background: white;
      flex-direction: column;
      box-shadow: 1px 1px 6px #d2cfcf;
    }

    .field_fnc {
      @extend .field;
      border-left: 4px solid $primaryColor80;
    }

    .field_zing {
      @extend .field;
      border-left: 4px solid $zingPrimaryColor80;
    }

    .fieldRequired_fnc {
      @extend .field_fnc;
      background-color: $secondaryColor40;
      box-shadow: none;
    }

    .fieldRequired_zing {
      @extend .field_zing;
      background-color: $zingSecondaryColor40;
      box-shadow: none;
    }

    .descSizeContainer {
      display: flex;
      justify-content: space-between;
      max-height: 50px;
      margin-bottom: 20px;
      padding-right: 32px;

      .description {
        flex: 1 0 60%;
        align-self: center;
        padding-left: 5px;
        font-weight: bold;
        font-size: 0.9em;
      }

      .description_fnc {
        @extend .description;
        color: $secondaryColor;
      }

      .description_zing {
        @extend .description;
        color: $zingSecondaryColor;
      }
    }

    .otherData {
      padding-left: 5px;
      display: flex;
      font-size: 0.9em;
      [class*='MuiInputBase-root'] {
        margin: 0;
      }

      .singleOtherData {
        display: flex;

        .resultData {
          margin-left: 4px;
        }
      }

      .otherDataTitle {
        font-weight: bold;
      }
    }

    .otherData_fnc {
      @extend .otherData;
      color: $secondaryColor80;

      .otherDataTitle {
        color: $secondaryColor;
      }
    }

    .otherData_zing {
      @extend .otherData;
      color: $zingSecondaryColor80;

      .otherDataTitle {
        color: $zingSecondaryColor;
      }
    }
  }
}

.otherData_wrapper {
  margin-left: 14px;
  padding-top: 3px;
}

.pagination {
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
}
