@use '../../../../modules.scss' as *;

.section {
	display: contents;
	width: 100%;
}

.searchSection {
    display: inline-flex;
    align-items: flex-start;
    width: 100%;
    gap: 15px;
}

.searchEmail {
	width: 50%;
}

.message {
	margin-bottom: 30px;
	height: 1.5rem;
	width: 100%;
	@include font($ff: $ff-primary, $fs: rem(14px), $lh: rem(17px), $fw: 400, $co: $grey);
}

@include media-breakpoint-down(sm) {
	.searchSection {
		display: flex;
		flex-direction: column;
		gap: 20px;
		align-items: normal;
	}
	.section {
		display: flex;
		flex-direction: column;
		gap: 20px;
	}
	.buttonSearch {
		padding: 12px 50px;
		margin-left: 20px;
		width: 100%;
	}
	.message {
		margin-top: 20px;
	}
}