@use '../../../../../modules.scss' as *;

.tableContainer {
  width: 100%;
}

.tableTitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.customTable {
  border-collapse: collapse;
  border: 1px solid  $secondaryColor20;
  margin-bottom: 20px;
  font-size: 14px;
  width: inherit;
  background-color: white;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  box-shadow: 0px 1px 1px 0px $secondaryColor40;
}

.customTableLoading {
  opacity: 0.7;
}

.customRow {
  border: 1px solid  $secondaryColor20;
  height: 45px;
}

.tableHeader {
  @extend .customRow;
  border: 1px solid  $secondaryColor20;
}

.cellHeader {
  padding: 20px;
  text-align: center;
  color: $secondaryColor80;
}

.tableRow {
  @extend .customRow;
}

.tableRow:hover {
  background-color: rgba(0, 0, 0, 0.04);
  transition: 0.9s;
}

.cellRow {
  text-align: left;
}


.emptyCellRow {
  padding: 20px;
  text-align: center;
}

.amountCell {
  width: 10%;
  text-align: right;
}

.dateCell {
  width: 20%;
  text-align: center;
}

.remarksCell {
  width: 35%;
  width: 35%;
  text-align: left;
  max-width: 260px;
  overflow: hidden;
}

.statusCell {
  width: 20%;
  text-align: center;
}

.actionCell {
  max-width: 100px;
}

.actionButton {
  text-align: right;
}


@include media-breakpoint-down(sm) {
  .customTable {
    font-size: 0.8rem;
  }
  .actionCell {
    width: 50px;
  }
  .actionButton {
    margin-top: 20px;
  }
 }
