.paymentControlDay {
  width: 48%;
}

.paymentDay {
  width: 48%;
}

.mainContainer {
  width: 100%;
}

.container {
  max-height: 180px;
  min-height: 160px;
  overflow-y: scroll;
  padding: 10px;
  border-radius: 6px;
  // background-color: rgba(255, 255, 255, 0.92);
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  font-size: 16px;
  border: 1px solid rgba(167, 163, 163, 0.3);
}

.sub_container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 5px;
}

.errors_count {
  display: flex;
  align-items: center;
  margin-right: 10px;
  color: grey;
  strong {
    margin-right: 5px;
  }
}

.error_line {
  display: flex;
  flex-direction: row;
  align-items: center;
}
