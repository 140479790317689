@use '../../../../modules.scss' as *;

%drawerBase {
  overflow-x: hidden;
  height: 100%;
}

div.drawer {
  white-space: pre-wrap;

  .listItem {
    justify-content: flex-start;

    [class*='anchorOriginTopRightRectangle'] {
      transform: initial;
    }
  }

  .listItem_items {
    padding: 5px 10px 5px 58px;
  }

  .icon {
    min-width: auto;
    margin-left: 7px;
  }
  .icon svg{
    width: 20px;
    height: 20px;
  }

  .text {
    color: inherit;
    display: none;
    padding-right: 24px;
    white-space: nowrap;
    text-transform: lowercase;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .text:first-letter {
    text-transform: uppercase;
  }

  .drawerOpen .text {
    display: block;
  }

  .icon + .text {
    margin-left: 10px;
  }

  .text + [class*='MuiSvgIcon-root'] {
    display: none;
  }

  .drawerOpen [class*='MuiSvgIcon-root'] {
    display: block;
  }

  .iconDropDown {
    display: none;

    .drawerOpen & {
      display: block;
    }
  }
}

.drawerOpen {
  width: 300px;
  transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms !important;
  @extend %drawerBase;

  .drawerOpen {
    height: calc(100% - #{$footerheight} - #{$headerheight});
  }
}

.drawerClose {
  width: 70px;
  transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms !important;
  @extend %drawerBase;

  .drawerClose {
    height: calc(100% - #{$footerheight} - #{$headerheight});

    [class*='Mui-selected'] {
      background-color: transparent;

      &:hover {
        background-color: transparent;
      }
    }
  }
}

.buttons {
  //min-height: 7vh;
  display: flex;
  padding: 0px 4px;
  align-items: center;
}

.containerListItem {
  color: $greyFont;
}

.buttons.left {
  justify-content: flex-end;
}

.buttons.close {
  justify-content: center;
}

.big_badge {
  height: 20px;
  width: 20px;
  background-color: $redLightColor;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 13px;
  position: absolute;
  right: 16px;
  display: none;

  .drawerOpen & {
    display: flex;
  }
}

%title {
  font-size: 15px;
  margin-left: 10px;
  margin-right: auto;
  white-space: nowrap;
}

.title_fnc {
  @extend %title;
  color: $primaryColor;
}

.title_zing {
  @extend %title;
  color: $zingPrimaryColor;
}

.testing {
  color: white;
}

@include media-breakpoint-down(sm) {
  .drawerOpen {
    display: none;
  }
}
