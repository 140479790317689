@use '../../../../modules.scss' as *;


.switch {
  display: inline-flex;
  box-sizing: border-box;
  width: 100%;
  padding: 20px 20px 0px 20px;
  height: 100%;
  justify-content: flex-end;
}

.usersTable {
  [class*='MuiTableCell-paddingNone'] {
    div {
      justify-content: end;
    }
  }
}

