@use '../../modules.scss' as *;

.containerWithMenu {
  width: 82%;
}
.menuRight {
  width: 15vw;
  background: white;
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  position: fixed;
  top: 90px;

  height: calc(100% - #{$footerheight} - #{$headerheight});
  right: 0;
}

.containerWithMenuCompo {
  width: calc(100% - 15vw);
  box-sizing: border-box;
  padding: 40px 40px 40px;
}

.containerWithMenuCompoPaddingOf {
  width: calc(100% - 15vw);
  box-sizing: border-box;
}
