@use '../../modules.scss' as *;

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin: 0px auto;
}

.containerLeft {
  @extend .container;
  margin: 0;
  justify-content: flex-end;
  padding: 0 20px;
}

.buttonContainer {
  min-width: 160px;
  height: 50px;
  &:only-child {
    //margin: 0 auto;
  }

  & + & {
    margin-left: 20px;
  }
}

@include media-breakpoint-down(sm) {
  .containerLeft {
    padding: 0;

    * + & {
      margin-top: 14px;
    }
  }

  .buttonContainer {
    height: auto;
    width: 100%;
  }
}
