@use '../../../../modules.scss' as *;

.container {
  min-width: 15vw;
  [class*='MuiButtonBase'] + [class*='MuiButtonBase'] {
    margin-top: 10px;
  }

  .importantButton {
    border-radius: 4px;
    background: $redLightColor;
    color: whitesmoke;
    width: calc(100% - 40px);
    margin: auto;
    padding: 10px 15px;

    & + .importantButton {
      margin-top: 15px;
    }

    &:hover {
      background-color: rgb(177, 65, 57);
    }
  }
}

.options_wrapper {
  margin: 25px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: $greyFont;
  button + button {
    margin-top: 15px;
  }
}

.options_wrapper_more_info {
  margin: 20px 0;
  button {
    padding: 8px 28px;
    justify-content: flex-start;
    margin: 0;
  }

  .goBackButton {
    line-height: 0
  }
}

@include media-breakpoint-down(sm) {
  .options_wrapper {
    margin: 20px;
  }

  .goBackButton {
    width: 100%;
    font-size: 14px;
    text-align: center;
    color: #da291c;
    text-transform: capitalize;
  }
}
