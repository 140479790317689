@use '../../modules.scss' as *;

.root {
  border: 0;
  border-radius: 4px;
  text-transform: uppercase;
  background-color: $primaryColor;
  padding: 14px 20px;
  @include font($ff: $ff-primary, $fs: rem(14px), $lh: rem(17px), $fw: 600, $co: $white);

  &.isSecondary {
    color: $greyFont;
    background-color: $greyLight;
  }

  &.isCancel {
    text-transform: initial;
    color: $primaryColor;
    background-color: transparent;
  }

  &:disabled {
    opacity: 0.5;
  }

  &:hover {
    opacity: 0.8;
  }
}

.fullWidth{
  width: 100%;
}
