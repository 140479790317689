@use '../../../../../../../../modules.scss' as *;

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 40px;
}

@include media-breakpoint-down(sm) {

}
