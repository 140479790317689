.container {
  display: flex;
  align-items: center;
  margin-top: 4rem;
  flex-wrap: wrap;
  justify-content: flex;
  flex-direction: row;
}

.buttonOption {
  width: 18.75rem;
  height: 6.25rem;
  border: 0.063rem solid #d7d8d8;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem 5rem 0 0;
  background: white;
  font-size: 1.25rem;
  cursor: pointer;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  font-family: 'Odudo';
  color: #fe5d52;
  //color: #da291c;
}
.buttonOption:hover {
  background-color: #e7e8e8;
}
.buttonOption:active {
  box-shadow: 0 0.031rem #5a5957;
  transform: translateY(4px);
}
