@use '../../../modules.scss' as *;

.modalHelp {
  padding: 20px;
  color: $greyFont;

  p {
    margin-bottom: 10px;

    a {
      color: $zingPrimaryColor80;
    }
  }
}
