@use '../../../modules.scss' as *;

.root {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
}

.size_50 {
  width: 49%;
  height: 70px;
}

.button {
  margin-left: auto;
}

@include media-breakpoint-down(sm) {
  .root {
    display: block;

    * + & {
      margin-top: 14px;
    }
  }

  .button {
    margin: 0;
    width: 100%;

    * + & {
      margin-top: 14px;
    }
  }
}
