@use '../../../../modules.scss' as *;

.list {
  display: flex;
  flex-wrap: wrap;
  width: calc(100vw - (100vw - 100%)); // the vw without the scrollbar
  grid-template-columns: repeat(6, 1fr);

  .item:nth-child(1n + 6) {
    background-color: #141618;
  }
  .item:nth-child(11n + 1) {
    background-color: #ff7d74;
  }
  .item:nth-child(7n + 3) {
    background-color: #ff6c63;
  }
  .item:nth-child(5n + 4) {
    background-color: #2b2c2e;
  }
  .item:nth-child(3n + 5) {
    background-color: #ff5d52;
  }
  .item:nth-child(9n + 2) {
    background-color: #434446;
  }
}

%content {
  box-sizing: border-box;
  padding: 20px;
  color: inherit;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 1.3vw;
  line-height: 2.4vw;
  padding: 10px;
  box-sizing: border-box;
  .title {
    z-index: 99;
  }
  .subtitle {
    z-index: 99;
    font-weight: bold;
  }
  .layer {
    position: absolute;
    top: 0px;
    left: 0px;
    opacity: 0.5;
    height: 100%;
    width: 100%;
  }
}

.title {
  font-size: 1.3vw;
  line-height: 1.7vw;
  font-weight: 400;
  .isLight {
    display: block;
  }
}

.item {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: white;
  text-transform: uppercase;
  text-decoration: none;

  height: 0;
  padding-bottom: calc(16.662vw - ((100vw - 100%) / 6));
  width: calc(16.662vw - ((100vw - 100%) / 6));

  position: relative;

  .content_fnc {
    @extend %content;
    font-family: Odudo;
    .layer {
      background: $primaryColor80;
    }
  }

  .content_zing {
    @extend %content;
    font-family: 'Oswald';
    .layer {
      background: $zingSecondaryColor;
    }
  }
}

.item:hover {
  .content_zing {
    .layer {
      display: none;
    }
  }
  .content_fnc {
    .layer {
      display: none;
    }
  }
}

.bottom_line {
  height: 10px;
  background-color: black;
}

.space_fabButton {
  height: 90px;
}

.empty {
  cursor: default;
  pointer-events: none;
}

.empty:hover {
  opacity: 1;
}

@include media-breakpoint-down(sm) {
  .list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 40px;
  }

  .item {
    width: 100%;
    padding-bottom: 100%;
  }

  .title {
    @include font($ff: $ff-secondary, $fs: rem(18px), $lh: rem(28px), $fw: 400, $co: $white);

    .isLight {
      font-weight: 300;
    }
  }
}
