@use '../../../../modules.scss' as *;

.form_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.message {
  padding: 1rem 0rem;
}
