@use '../../../../modules.scss' as *;


.modal_content {
  [class*='MuiTableCell-root'] {
    padding: 12px 25px;
  }
}

.message_area {
  font-size: 0.9rem;
  display: block;
  margin-top: 20px;
  padding-left: 5px;
  color: $greyDark;
}

.message_list {
  list-style-type: none;
  overflow-y: auto;
  border: 1px solid $greyLight;
  border-radius: 4px;
  height: 60px;
  margin: 5px 0px;
  padding: 5px;
}

.list_item {
  padding: 5px 5px;
}

.list_error {
  color: $primaryColor;
}

[class*='MuiDialogActions-spacing'] {
  padding-top: 0px !important;
}
