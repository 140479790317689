@use '../../../../modules.scss' as *;

.dialogContent {
  padding: 10px 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 23vw;

  div {
    margin-right: 4px;
  }
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@include media-breakpoint-down(sm) {
  .dialogContent {
    width: auto;
  }
}
