@use '../../../modules.scss' as *;

.container {
  //    padding-left: 12px;
  [class*='title_title'] {
    padding-left: 12px;
  }

  [class*='inputs_size_20'] {
    margin-right: 16px !important;
    width: 30%;
  }

  [class*='selectInput_size_20'] {
    margin-right: 16px !important;
  }

  [class*='MuiTable-root'] {
    table-layout: auto !important;
  }

}

.accounting_form {
  width: 98%;
  padding-left: 1%;
  padding-right: 1%;
  &__container {
    display: flex;
    flex-direction: column;
    width: 100%;
    &__select {
      margin-top: 10px;
    }
  }
}

.academic_year_selector {
  width: 250px;
}

.accountingDataSummaryForm {
  display: flex;
  flex-direction: row;
  gap:10px;
}

.supplier_container {
  display: flex;

  [class*='selectInput_size_20'] {
    margin-right: 16px !important;
    width: 30%;
  }

  [class*='selectInput_size_30'] {
    width: 30%;
  }
}

.carga_347_checkbox {
  width: 30%;
  padding: 8px;
}


.info {
  margin: 2rem 0;
  width: 100%;
  text-align: center;

  h3 {
    color: $greyFont !important;
  }
}

.tableWrapper tr > td:nth-child(2) {
  padding-left: 3.8rem;
}

.tableWrapper tr > td:nth-child(4) {
  padding-left: 3.5% !important;
}


.accountingSummary_green {
  input {
    color: green;
  }
}
.paymentSettingForm{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  width: 100%;
}

.paymentDescription{
  grid-column: 1 / span 2;
}

.buttonActionRight{
  display: flex;
  justify-content: flex-end;
}

.submitPaymentSettings{
  grid-column: 2 / 2;
}

.sectionContainer {
  margin-top: 20px;
  margin-bottom: 30px;
}

.remainderForm, .paymentAdvanceForm, .adminManagementForm {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
  width: 100%;
  align-items: flex-start;
}

.remainderFormActions {
  display: flex;
  gap: 10px;
  grid-column: 3 / span 2;
}

.buttonsWrapper {
  margin-top: 3px;
}

.iconButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  max-height: 45px;
  svg {
    width: 20px;
  }
}
