@use '../../modules.scss' as *;

@mixin inputSelect {
  @include font($ff: $ff-primary, $fs: rem(16px), $lh: rem(24px), $fw: 400, $co: $secondaryColor);
  border: 1px solid $greyLight;
  border-radius: 4px;
  background-color: $white;
  padding: 12px 15px;
  width: 100%;
  display: block;

  &::placeholder {
    @include font($ff: $ff-primary, $fs: rem(16px), $lh: rem(24px), $fw: 400, $co: $grey);
  }

  &:focus {
    border-color: $secondaryColor;
  }

  &:disabled {
    background-color: $background;
    color: $zingSecondaryColor40;
    border: 1px solid $secondaryColor20;
  }
}

@mixin inputSelectBorderError {
  border-color: $primaryColor;
  box-shadow: 0px 0px 0px 1px $primaryColor inset;
}

@mixin label {
  @include font($ff: $ff-primary, $fs: rem(16px), $lh: rem(24px), $fw: 400, $co: $grey);
  display: block;
}

@mixin labelMobile {
  @include font($fs: rem(14px), $lh: rem(16px));
}
