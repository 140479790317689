@use '../../modules.scss' as *;

.container {
  background-color: $white;
  padding: 2rem 4rem;
  color: $greyFont;

  & a {
    color: $greyFont;
    text-decoration: none;
  }

  & a:hover {
    color: $redLightColor;
  }

  & ul {
    list-style: none;
  }

  & li {
    margin-bottom: 0.5rem;
  }

  .wrapper {
    box-sizing: border-box;

    & * {
      box-sizing: border-box;
    }
  }

  .wrapper {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: $white;
    width: 65%;
  }

  .content > * {
    margin-bottom: 2rem;
    line-height: 1.8;
  }

  .title {
    border-left: 1rem solid $redLightColor;
    padding-left: 1rem;
    margin-bottom: 4rem;
    font-weight: 100;
    font-size: 2.5rem;
  }

  .subtitles {
    font-style: italic;
  }

  // Own styles

  & .table {
    display: flex;
    flex-direction: column;

    & > section {
      margin: 1rem 0;
    }

    & .tableRow {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      & .rowTitle {
        border-left: solid 3px $redLightColor;
        padding-left: 0.8rem;
        width: 20%;
      }
      & .rowSubject {
        width: 70%;
        margin: 0 2rem;
        & > ul {
          padding: 0;
        }
      }
      & .rowInfo {
        width: 10%;
        cursor: pointer;
      }

      & .rowInfo:hover {
        color: $redLightColor;
      }
    }
  }
}
