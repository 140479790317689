@use '../../../../../modules.scss' as *;

.message_root {
  line-height: 1.5;
  text-align: justify;
}

.message_extension {
  margin-top: -30px;
  margin-left: 24px;
  margin-bottom: 30px;
}

.message {
  margin-top: 10px;
  margin-left: 10px;
  color: #666666;
  width: 100%;
  * + & {
    margin-left: 10px;
  }
}

.link {
  padding-left: 5px;
}

@include media-breakpoint-down(sm) {
  .message_extension {
    padding: 0;
    * + & {
      margin-top: 10px;
    }
  }
  .message {
    @include font($ff: $ff-secondary, $fs: rem(12px), $lh: rem(18px), $fw: 400, $co: $greyFont);
  }
}
