@use '../../modules.scss' as *;

.root {
  display: table;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #cdcdcd;

  * + & {
    margin-top: 20px;
  }
}

.mobileWrapper {
  display: block;
  padding: 0 20px;
}

.headingCell {
  padding: 12px 15px;
  border-bottom: 1px solid #cdcdcd;
  @include font($ff: $ff-primary, $fs: rem(14px), $lh: rem(22px), $fw: 700, $co: $greyFont);
  text-align: left;
}

.bodyCell {
  padding: 12px 15px;
  @include font($ff: $ff-primary, $fs: rem(14px), $lh: rem(22px), $fw: 400, $co: $secondaryColor);
  text-align: left;

  .row + .row & {
    border-top: 1px solid #cdcdcd;
  }
}

@include media-breakpoint-down(sm) {
  .mobileWrapper {
    display: block;
    padding: 0 20px;
  }

  .headingCell {
    padding: 12px 15px;
    border-bottom: 1px solid #cdcdcd;
    @include font($ff: $ff-primary, $fs: rem(14px), $lh: rem(22px), $fw: 700, $co: $greyFont);
    text-align: left;
  }

  .bodyCell {
    padding: 12px 15px;
    @include font($ff: $ff-primary, $fs: rem(14px), $lh: rem(22px), $fw: 400, $co: $secondaryColor);
    text-align: left;

    .row + .row & {
      border-top: 1px solid #cdcdcd;
    }
  }
}
