.fileNameContainer {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-bottom: 10px;

  .placeHolder {
    font-weight: bold;
  }
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  width: 200px;
  height: 85px;
  margin: auto;
  justify-content: space-between;
}

.importOk {
  display: flex;
  text-align: center;
  align-items: center;
  width: 300px;
  margin: auto;
  margin-top: 20px;
}
