@use '../../modules.scss' as *;

.root {
  font-size: 30px;
  font-weight: normal;
  margin: 0 0 30px;
}

.title_fnc {
  color: $primaryColor;
}

.title_zing {
  color: $zingPrimaryColor;
}

@include media-breakpoint-down(sm) {
  .root {
    @include font($ff: $ff-secondary, $fs: rem(20px), $lh: rem(30px), $fw: 400, $co: $primaryColor);
    margin: 0;
  }
}
