@use '../../../../modules.scss' as *;

.title {
  color: $primaryColor80;
  font-size: 24px;
  background: rgba(102, 102, 102, 0.1);
  padding: 20px 30px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;

  :global(.MuiDialogTitle-root) {
    padding: 0;
    display: flex;

    h2 {
      line-height: 1;
      font-weight: 600;
      padding: 0px;
    }
  }
}

.dialogContent {
  padding: 0px !important;
  width: 930px;
  margin-bottom: 20px;
  margin-left: 10px;
}

.btn_create {
  display: flex;
  justify-content: flex-end;
  [class*='MuiButtonBase'] {
    width: 20%;
    margin-left: 10px;
  }
}

.enrollmentForm {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0px 20px;
}

.userExistLayout {
  padding-top: 1em;
  padding-right: 1em;
  padding-left: 1em;
}

.userExistData {
  list-style-type: none;
  padding: 1em;
  margin: 0;
  li {
    margin-top: 5px;
  }
}

.userExistInfo {
  list-style-type: none;
  padding: 0;
  margin-top: 30px;
  margin-bottom: 0px;
  li {
    margin-top: 5px;
  }
}

@include media-breakpoint-down(sm) {
  .dialogContent {
    width: auto;
  }
}
