@use '../../../modules.scss' as *;

p {
  margin: 0;
}

.title {
  color: $primaryColor80;
  font-size: 24px;
  background: rgba(102, 102, 102, 0.1);
  padding: 20px 30px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;

  :global(.MuiDialogTitle-root) {
    padding: 0;
    display: flex;

    h2 {
      line-height: 1;
      font-weight: 600;
      padding: 0px;
    }
  }
}

.dialog_root {
  [class*='MuiDialogContent-root'] {
    padding: 0px !important;
  }

  [class*='MuiDialog-paperWidthXl'] {
    width: 50%;
    height: auto;
  }
}

.dialogContent {
  padding-bottom: 1em !important;
}

.dialogActions {
  padding-bottom: 1em !important;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 25px 30px 40px;

  :global(.MuiFormControl-root) {
    width: 50%;
    flex-grow: 1;
    margin: 0;
    margin-bottom: 0px;

    & + :global(.MuiFormControl-root) {
      margin-left: 10px;
    }
  }

  [class*='keyboardDatePickerController_size'] {
    width: 50%;
  }
}

.selectors {
  display: grid;
  grid-template-columns: 1fr 1fr;

  &__sub {
    display: flex;
  }
}

.btn_create {
  width: 100%;
  display: flex;
  justify-content: flex-end;

  [class*='MuiButtonBase'] {
    width: 15%;
    margin-left: 10px;
  }
}

.combo_style {
  [class*='comboSearch_comboContainerFull'] {
    width: 97%;
    margin-left: 10px;
  }
}

.template_size {
  [class*='selectInput_size_50'] {
    width: 50% !important;
  }
}

.select_role {
  width: 100%;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  gap: 10px;
}

.select_wrapper {
  width: 85%;
}

.active_role_permissions {
  min-height: 240px;

  h4 {
    color: $primaryColor80;
  }
}

.user_role_permission_list {
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  border: solid 0.1px rgba(0, 0, 0, 0.12);

  span {
    font-size: 0.9em;
  }
}

.role_permission_list {
  @extend .user_role_permission_list;
  max-height: 175px;
}

.user_permission_list {
  @extend .user_role_permission_list;
  max-height: 500px;

  [class*='MuiCheckbox-root'] {
    padding-top: 0px;
    padding-bottom: 0px;
  }
}

.table_role {
  padding: 0px;
  height: 250px;

  .MuiToolbar-regular-1487 {
    min-height: 48px;
  }

  [class*='MuiToolbar-regular'] {
    min-height: 48px;
  }

  [class*='MuiTableCell-head'] {
    padding: 10px 20px !important;
  }

  [class*='MuiTableCell-root'] {
    padding: 0px 20px;
  }

  [class*='tableWithEdit_customContainer'] {
    border: solid 0.1px rgba(0, 0, 0, 0.12);
  }
}
