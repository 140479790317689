@use '../../../modules.scss' as *;

.root {
  margin: 0;
  padding: 0;
}

.description {
  @include font($ff: $ff-primary, $fs: rem(16px), $lh: rem(20px), $fw: 400, $co: $grey);
}

.list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 10px 20px;

  * + & {
    margin-top: 10px;
  }
}

@include media-breakpoint-down(sm) {
  .description {
    @include font($fs: rem(14px), $lh: rem(16px));
  }
}
