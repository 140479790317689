@use '../../../../modules.scss' as *;

.containerCombo {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.cardContainer {
  width: 100%;
}

.size_50 {
  width: 49%;
}

.size_30 {
  width: 31%;
}

.green fieldset {
  border: 2px solid green !important;
}

.doc_container {
  [class*='title_title'] {
    margin: 0px 35px 0px;
  }
}

.paymentForm {
  [class*='checkBoxCompo_checkContainer'] {
    margin-bottom: 20px;
  }
 
}

.buttonsWrapper {
  margin-top: 3px;
}
.iconButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  max-height: 45px;
  svg {
    width: 20px;
  }
}

.buttonActionRight{
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.providerContainer{
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 73px;
}

.providerInfo {
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 80%;
}

.buttonActionProvider{
  display: flex;
  justify-content: flex-end;
  width: 20%;
}



@include media-breakpoint-down(sm) {
  .root {
    * + & {
      margin-top: 20px;
    }
  }
}
