@use '../../../modules.scss' as *;

.root {
  display: none;
}

.button {
  & + & {
    margin-left: 10px;
  }
}

@include media-breakpoint-down(sm) {
  .root {
    display: flex;
    justify-content: space-between;
  }
}
