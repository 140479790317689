.wrapper_big {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px 0;
}
.wrapper_medium {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 0;
}
.wrapper_p {
  color: #ff4d48;
  font-size: 1.1em;
  margin-left: 5%;

  * & {
    margin-left: 8px;
  }
}

.wrapper_small {
  width: 100%;
}
