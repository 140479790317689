@use '../../../modules.scss' as *;

.createEditContainer {
  padding: 20px;

  .followUpCreateContainer {
    display: flex;
    flex-direction: row;
    width: 100%;

    .followUpLeft {
      flex: 1;
    }

    .followUpRight {
      margin-left: 20px;
      flex: 1;
    }
  }

  .buttonContainer {
    display: flex;
    margin-top: 20px;
    justify-content: space-between;
  }

  .buttonGroupContainer {
    margin-top: 20px;
    justify-content: flex-start;
    display: flex;
  }
}

.listUploads {
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin-top: 20px;
  border-radius: 5px;
  border: 1px solid rgba(102, 102, 102, 0.3);

  .uploadsTitles {
    display: flex;

    div {
      flex: 1;
      color: $primaryColor80;
    }
  }

  .uploadsFiles {
    padding: 10px 0px;
    display: flex;
    flex-wrap: wrap;
    .documentItem {
      display: flex;
      margin-bottom: 5px;

      div {
        flex: 1;
        color: $greyFont;
      }

      .action {
        text-align: center;

        svg {
          width: 30px;
          color: $greyFont;
          cursor: pointer;

          &:hover {
            color: $primaryColor80;
          }
        }
      }
    }
  }
}

.iconDisabled {
  pointer-events: none;
  color: gainsboro !important;
}

.chip {
  margin: 5px !important;
}
