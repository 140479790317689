@use '../../modules.scss' as *;

.root {
  height: $headerheight;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  background-position: right;
  background-size: cover;
  position: relative;
}

.wrapper_fnc {
  border-bottom: 3px solid $primaryColor;
  background-color: $primaryColor80;
}

.wrapper_zing {
  border-bottom: 3px solid $zingPrimaryColor;
  background-color: $zingSecondaryColor;
}

.logo {
  width: 136px;
  height: auto;
}

.menu {
  display: none;
}

.menuButton {
  display: none;

  .menuIcon {
    width: 15px;
    height: 12px;
  }
}

.menuButtonClose {
  display: none;
}

.menuLanguage {
  display: flex;
  flex-wrap: wrap;
  padding: 8px 13px;
  list-style-type: none;
  border-top: 1px solid $greyFont;
  width: 100%;
  margin: auto 0 0;
  padding: 10px 0 0;
  box-sizing: border-box;
}

.menuLanguageItem {
  & + & {
    margin-left: 10px;
  }
}

.menuText {
  @include font($ff: $ff-primary, $fs: rem(14px), $lh: rem(20px), $fw: 400, $co: $grey);
}

@include media-breakpoint-down(sm) {
  .root {
    position: fixed;
    width: 100%;
    height: ele-headerHeight(mobile);
    z-index: zi-level(menu);

    [class*='fabButtons'] {
      display: none;
    }
  }

  .logo {
    width: 110px;
    display: block;
  }

  .menuButton,
  .menuButtonClose {
    border: none;
    background: transparent;
    position: absolute;
    right: 10px;

    padding: 10px;
  }

  .menuButton {
    top: 18px;
    display: block;

    .menuIcon {
      width: 15px;
      height: 12px;
      display: block;
    }
  }

  .menuButtonClose {
    top: 14px;
    display: flex;
    align-items: center;

    .menuButtonCloseIcon {
      width: 12px;
      height: 12px;
      display: block;
    }
  }

  .menuButtonCloseIcon {
    * + & {
      margin-left: 10px;
    }
  }

  .menuButtonText {
    @include font($ff: $ff-primary, $fs: rem(12px), $lh: rem(20px), $fw: 400, $co: $white);
  }

  .menu {
    position: fixed;
    top: ele-headerHeight(mobile);
    right: 0;
    width: 100%;
    height: 100%;
    max-height: calc(100vh - #{ele-headerHeight(mobile)});
    z-index: zi-level(menu);
    background-color: $secondaryColor;
    flex-direction: column;

    &.isOpen {
      display: flex;
    }
  }

  .menuItem {
    & + & {
      border-top: 1px solid $greyDark;
    }
  }

  .menuText {
    @include font($ff: $ff-primary, $fs: rem(14px), $lh: rem(20px), $fw: 400, $co: $grey);
  }

  .menuLink {
    padding: 15px 20px;
    display: block;
    @include font($co: $grey);
    text-decoration: none;

    .isSelected & {
      @include font($co: $white);
    }
  }

  .menuProfile {
    margin-top: auto;
  }

  .menuProfileTextWrapper {
    @include font($ff: $ff-primary, $fs: rem(14px), $lh: rem(20px), $fw: 400, $co: $grey);
  }

  .menuProfileLink {
    padding: 15px 20px;
    display: block;
    @include font($co: $grey);
    text-decoration: none;

    &.hasIcon {
      display: flex;
      align-items: center;
    }
  }

  .menuProfileText {
    * + & {
      margin-left: 10px;
    }
  }

  .menuLanguage {
    border-top: 1px solid $greyDark;
    padding-inline-start: 15px;
    * + & {
      margin-top: 10px;
    }
  }

  // .menuLanguageItem {
  //   & + & {
  //     margin-left: 10px;
  //   }
  // }

  .menuLanguageText {
    @include font($ff: $ff-primary, $fs: rem(12px), $lh: rem(20px), $fw: 400, $co: $grey);
  }

  .menuLanguageLink {
    @include font($co: $grey);
    text-decoration: none;
    padding: 7px 7px;
  }
}
