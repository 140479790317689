@use '../../modules.scss' as *;

.root {
  @include font($ff: $ff-secondary, $fs: rem(20px), $lh: rem(24px), $fw: 400, $co: $primaryColor);
  margin: 0;

  * + & {
    margin-top: 20px;
  }
}

@include media-breakpoint-down(sm) {
  .root {
    @include font($ff: $ff-secondary, $fs: rem(16px), $lh: rem(18px), $fw: 400);
  }
}
