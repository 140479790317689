.enrollment {
  margin-top: 1em;
  margin-bottom: 1em;
}

.titleError{
  width: 100%;
  text-align: center;
  margin-top: 11%;
}
