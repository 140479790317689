//LAYOUT SIZES
$xs: 0;
$s: 375px;
$sm: 768px;
$md: 1024px;
$xl: 1280px;
$lg: 1366px;
$xxl: 1440px;

$grid-breakpoints: (
  xs: $xs,
  s: $s,
  sm: $sm,
  md: $md,
  xl: $xl,
  lg: $lg,
  xxl: $xxl,
) !default;

$zingPrimaryColor: #d64a45;
$zingPrimaryColor80: #fe5d52;
$zingPrimaryColor40: #f9afaa;
$zingPrimaryColor20: #f8d4d2;

$zingSecondaryColor: #1d1d1b;
$zingSecondaryColor80: #666666;
$zingSecondaryColor40: #a4a4a4;
$zingSecondaryColor20: #f7f7f7;
$zingSecondaryColor10: #ebebeb9f;

$primaryColor: #da291c;
$primaryColor80: #e15449;
$primaryColor40: #ec948e;
$primaryColor20: #f8d4d2;

$secondaryColor: #2a2928;
$secondaryColor80: #5a5957;
$secondaryColor40: #9a9b9b;
$secondaryColor20: #d7d8d8;

$redLightColor: #fe5d52;

$white: #ffffff;
$black: #000000;
$greyFont: #666666;
$greyDark: #404040;
$greyT: #737272;
$grey: #808080;
$greyLight: #cdcdcd;
$background: #fafafa;

//StepsMenu
$greenBubble: #3a9f1b;
$orangeBubble: #fea352;
$yellowBubble: rgb(248, 216, 33);

$footerheight: 90px;
$headerheight: 90px;

// HTML font-size
$fs-root: 16px;

@function rem($size) {
  @return math.div($size, $fs-root) * 1rem;
}

// Font Family old
$ff-primary: 'Fira Sans', Verdana, sans-serif;
$ff-secondary: 'Oswald', Tahoma, sans-serif;
$ff-alt: 'Odudo', Tahoma, sans-serif;

//LAYOUT SIZES
$xs: 0;
$s: 375px;
$sm: 768px;
$md: 1024px;
$xl: 1280px;
$lg: 1366px;
$xxl: 1440px;

$grid-breakpoints: (
  xs: $xs,
  s: $s,
  sm: $sm,
  md: $md,
  xl: $xl,
  lg: $lg,
  xxl: $xxl,
) !default;

$ele-headerHeight: (
  mobile: 70px,
);

// z-index
$zi-level: (
  modal: 100000,
  // Modals appear on top of everything
    menu: 10000,
  // The header+menu is the second priority, only modals get over it
    fader: 1,
  // The fader itself is above regular content
    negative: -1,
);

/* PLACEHOLDERS */

%material_button_base {
  padding: 8px 20px;
  font-size: 0.875rem;
  min-width: 64px;
  box-sizing: border-box;
  min-height: initial;
  font-weight: bold;
  line-height: 1.75;
  border-radius: 4px;
  text-transform: uppercase;
  box-shadow: none;
}

%pageStyles {
  margin: 0 auto;
  padding: 40px 40px 40px;
  max-width: 1334px;
  width: 100%;
}

%marginInputs {
  margin-bottom: 20px;
}

%primary_button {
  @extend %material_button_base;
  color: white;
  background-color: $primaryColor80;
}

%zingPrimary_button {
  @extend %material_button_base;
  color: white;
  background-color: $zingPrimaryColor80;
}

%secondary_button {
  @extend %material_button_base;
  color: $secondaryColor80;
  background-color: #d8d8d8;
}

%zingSecondary_button {
  @extend %material_button_base;
  color: $zingSecondaryColor80;
  background-color: #d8d8d8;
}
