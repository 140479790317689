@use '../../../modules.scss' as *;

.login_form_fnc {
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  height: 70%;
}

.login_form_zing {
  @extend .login_form_fnc;
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    box-shadow: 0 0 0 30px $zingPrimaryColor20 inset !important;
    -webkit-box-shadow: 0 0 0 30px $zingPrimaryColor20 inset !important;
    opacity: 0.5;
  }
}

.checked {
  margin-top: 6%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.13);
  padding-bottom: 5%;
  color: grey;
}

.loading {
  margin-right: 47%;
}

.lopd-paragraph {
  margin-top: 20px;
  margin-bottom: 20px;
}

@include media-breakpoint-down(sm) {
  .inputs div {
    background-color: $white;
  }

  .login_form_zing {
    .inputs div {
      background-color: transparent;
    }
  }

  .loading {
    margin: 0;
  }
}
