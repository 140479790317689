@use '../../modules.scss' as *;

.tabs_container {
  background-color: white;
  min-width: 100%;
  padding-top: 10px;
  height: 100%;

  .monthTitle {
    color: $redLightColor;
    max-width: 1000px;
    margin: auto;
    font-size: 40px;
    font-weight: bold;
    text-transform: capitalize;

    .capitalize {
      text-transform: capitalize;
    }
    span {
      text-transform: capitalize;
    }
  }

  .franjas {
    max-width: 1000px;
    margin: auto;
    margin-bottom: 1.5rem;
    font-size: 18px;
    color: $redLightColor;
    font-weight: bold;
  }

  .top_tabs_container {
    display: flex;
    flex-direction: row;
    user-select: none;
    justify-content: center;
    max-width: 1000px;
    min-width: 1000px;
    margin: 0px auto 0px;
    .top_tabs_mobile {
      display: flex;
      width: 90%;
      position: relative;
      height: 80px;
      margin: 20px 0px 20px;
      max-width: 1000px;
    }

    .date_box_active {
      @extend .date_box;
      box-sizing: border-box;
      font-weight: bold;

      .date_box_day {
        font-weight: normal;
        display: flex;
        align-self: center;

        div {
          border-radius: 50%;
          padding: 5px;
          background: $redLightColor;
          color: $white;
          font-size: 26px;
          font-weight: bold;
          height: 40px;
          width: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .date_box_day_bold {
        font-weight: bold;
        text-transform: capitalize;
        font-size: 16px;
        line-height: 17px;
        text-align: center;
      }
    }
    .date_box {
      cursor: pointer;
      color: $redLightColor;
      font-size: 16px;
      text-align: center;
      margin-left: 10px;
      padding: 10px 0px;
      border-bottom: none;
      justify-content: space-between;
      flex: 0 0 18.5%;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;

      .date_box_day {
        font-weight: normal;
        font-size: 26px;

        span {
          font-size: 20px;
          font-weight: bold;
        }
      }
      .date_box_day_bold {
        font-weight: bold;
        text-transform: uppercase;
      }
    }

    .date_box_disabled {
      @extend .date_box;
      cursor: default;
      opacity: 0.2;
      color: $greyFont;
    }
  }
}
.show_more_icon,
.show_less_icon {
  cursor: pointer;
  display: flex;
  align-items: center;

  svg {
    font-size: 3.5rem;
    color: $greyFont;
  }
}
.show_whitout_icon {
  padding: 28px;
}
.main_content {
  width: 90%;
  max-width: 1000px;
  margin: 0 auto;
  padding-bottom: 15px;

  .franjas_no_disponible {
    @extend .franjas;
  }

  .notavailable {
    cursor: pointer;
    flex: 0 0 6%;
    height: 30px;
    text-align: center;
    padding: 5px;
    margin-right: 9px;
    margin-left: 9px;
    margin-bottom: 10px;
    border: 1px solid #dedddc;
    background: $white;
    font-size: 18px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #747474;
  }

  .online {
    @extend .notavailable;
    background: $greenBubble;
    color: $white;
  }
  .presencial {
    @extend .notavailable;
    background: $yellowBubble;
    color: $white;
  }
  .ambas {
    @extend .notavailable;
    background: $orangeBubble;
    color: $white;
  }
  .busy {
    @extend .notavailable;
    background: #dedddc;
    pointer-events: none;
  }
  .available_times_container {
    display: flex;
    flex-flow: column wrap;
    align-content: center;
    .available_times_disabled {
      display: none;
    }
    height: 55vh;
    & > * {
      width: 170px;
    }
    .available_times_content {
      width: 100%;
      margin-left: 40%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .available_times_icons {
      display: flex;
      // flex-direction: column;
      align-items: center;
    }

    .available_times_clicked {
      cursor: pointer;
      flex: 0 0 14%;
      text-align: center;
      padding: 10px;
      height: 45px;
      margin-left: 18px;
      margin-bottom: 10px;
      border: 1px solid #f1f1f1;
      background-color: yellow;
    }
  }
}
