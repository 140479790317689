@use '../../../modules.scss' as *;

p {
  margin: 0;
}

.title {
  color: $zingPrimaryColor80;
  font-size: 24px;
  background: rgba(102, 102, 102, 0.1);
  padding: 20px 30px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;

  :global(.MuiDialogTitle-root) {
    padding: 0;
    display: flex;

    h2 {
      line-height: 1;
      font-weight: 600;
      padding: 0px;
    }
  }
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.paper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 40%;
  width: 20%;
  background-color: white;
  border: 2px solid #000;
  box-shadow: inset;
  padding: 2%;
}

.button2 {
  width: 40%;
  height: 100%;
  border: none;
  background-color: rgb(180, 172, 172);
}

.button {
  width: 20%;
  height: 10%;
  border: none;
  background-color: rgb(180, 172, 172);
}

.button:hover {
  background-color: rgb(151, 146, 146);
}

.button2:hover {
  background-color: rgb(151, 146, 146);
}

.position {
  display: flex;
  justify-content: space-around;
  width: 100%;
  height: 10%;
}

.test {
  max-width: auto;
}

.text {
  margin-bottom: 5%;
}

.dialogContent {
  padding: 0px !important;
  width: 800px;
  height: 435px;
}

.newscholarship {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 45px 30px 40px;

  .block {
    :global(.MuiFormControl-root) {
      width: auto;
      flex-grow: 1;
      margin: 0;
      min-height: initial;
      margin-bottom: 0px;

      & + :global(.MuiFormControl-root) {
        margin-top: 15px;
      }
    }
  }

  [class*='optionsModal'] {
    font-size: 14px;
    line-height: 20px;
  }

  [class*='MuiTypography'] {
    font-size: 14px;
    line-height: 20px;
  }

  [class*='PrivateSwitchBase'] {
    padding: 6px;
  }

  [class*='MuiSvgIcon'] {
    width: 18px;
    height: 18px;
  }

  label[class*='radioButtons_optionsModal'] {
    margin-left: -6px;
  }

  .email {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    .input_email {
      width: 100% !important;
    }

    button {
      margin-left: 15px;
      height: 51px;
      padding-left: 25px;
      padding-right: 25px;

      &:disabled {
        opacity: 0.5;
        color: white;
      }
    }
  }

  .block {
    color: $greyFont;
    margin: auto;
  }

  .btns {
    width: 100%;
    margin-top: 1%;
    display: flex;
    justify-content: space-around;

    div {
      margin: 0;
      margin-right: 3px;
      margin-left: 3px;
    }
  }
}

.downloadLink {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.consentContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.consentBlock {
  span {
    font-size: 0.8rem;
  }

  a {
    color: $greyFont;
    margin-bottom: 0.1rem;
    margin-top: 13px;
    text-decoration: none;
  }

  a:hover {
    cursor: pointer;
    color: red;
    text-decoration: underline;
  }
}

.helperText {
  color: $zingPrimaryColor80;
  font-size: 14px;
}

.message {
  width: 90%;
  color: $greyFont;
  margin: auto;
  border-left: 3px solid $zingPrimaryColor80;
  padding-left: 8px;
  margin-bottom: 1%;
  margin-left: 2.5rem;
}

.buttonActions {
  padding: 0 25px 20px;

  [class*='MuiDialogActions-root'] {
    padding: 0;
  }

  button {
    height: 51px;

    &:disabled {
      opacity: 0.5;
      color: white;
    }
  }
}

@include media-breakpoint-down(sm) {
  .dialogContent {
    width: auto;
  }
}
