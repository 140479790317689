.error {
  margin-top: 20px;
  color: red;
  font-weight: bold;
}
.spinner {
  margin-top: 20px;
}

.registerContainer {
  margin: 20px 0px;
  max-width: 400px;
  &__zing {
    @extend .registerContainer;
    color: white;
  }
  &__fnc {
    @extend .registerContainer;
  }
}
.languageContainer {
  margin-top: 3rem;
}

.actions {
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
  gap: 1rem;
  [class*='action'] {
    width: 100%;
  }
}
