@use '../../../modules.scss' as *;

.invoiceFormContainer {
  :global(.MuiFormControl-root.inputs_size_20) {
    width: 23.5%;
  }
}

.downloadButton {
  border: none;
  background: none;
  width: auto;
}

.invoiceDetailsContainer {
  padding: 20px 0 20px;
}

.helperText {
  color: $zingPrimaryColor80;
  font-size: 12px;
  text-align: center;
}

[class*='radioButtons_questionText'] {
  margin-bottom: 5px;
}

.chip {
  svg {
    margin-left: 10px;
  }
}

.invoiceContractNumber {
  display: flex;
  align-items: self-start;
  width: 23.5%;
}

.contractViewIcon {
  margin-top: -8px;
  font-size: 2.6rem !important;
}