.invoiceDetailsContainer {
  align-items: center;
  width: 100%;
}

.helperText {
  color: #f44336;
  font-size: 14px;
  margin-left: 14px;
  margin-right: 14px;
}
