@use '../../modules.scss' as *;

.generalContainer {
  width: 100%;
  .generalWrap {
    width: 85%;
    margin: auto;
  }

  .generalWrapDisabled {
    display: none;
  }

  .wrapForm {
    border: 1px solid black;
    margin: auto;
    padding: 60px;
    margin-bottom: 60px;
    border-radius: 15px;
  }

  .buttonsContainer {
    display: flex;
    justify-content: space-between;
    margin: auto;
    max-width: 350px;

    button {
      width: 160px;
    }
  }
}

.btns {
  margin: 0;
}

.responsibleContainer {
  padding: 8px 24px;
  .label {
    margin: 8px 0;
  }
}

.form {
  width: 98%;
  padding-left: 1%;
  padding-right: 1%;
  &__container {
    display: flex;
    flex-direction: column;
    width: 100%;
    &__select {
      margin-top: 10px;
    }
  }
}

.messageInfo {
  margin: 2px 0;
}

.messageInfoRed {
  margin: 2px 0;
  color: $primaryColor80 !important;
}

.compoundItem {
  width: 100%;
  margin: 0 0 2rem 0;
  margin-bottom: -1rem;

  label {
    font-size: 1.1em;
    color: $greyFont;
    margin-bottom: 1rem;
  }
}

.label {
  font-size: 1.1em;
  color: $greyFont;
  margin-bottom: 1rem;
  padding-left: 0.5rem !important;
  border-left: 6px solid $redLightColor;
}

