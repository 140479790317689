.config {
  padding: 40px 20px 20px 20px;
}
//simpleList_active
.containerConfig {
  width: 100%;
  box-sizing: border-box;
  padding-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;

  [class*='simpleList_containerList'] {
    width: 25%;
  }
  [class*='simpleList_list'] {
    width: 70%;
    min-width: 95%;
    [class*='simpleList_containerItem'] {
      min-width: 100%;
      display: flex;
      [class*='MuiTextField-root'] {
        width: 20%;
      }
    }
  }

  [class*='simpleList_active'] {
    width: 159px !important;
    padding-right: 15px;
    [class*='simpleList_list'] {
      //min-width: 100%;
    }
  }
}

[class*='MuiInput-root'] {
  width: 90%;
}

.form_costCenter {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
