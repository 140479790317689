@use '../../../modules.scss' as *;

.btns {
  display: flex;
  justify-content: flex-end;

  [class*='buttonContainer'] {
    margin-left: 15px;
  }
}

.table {
  width: 100%;
  display: flex;
}

.table_value {
  width: 100%;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.table_delete {
  width: 100%;
  display: flex;

  //align-items: flex-start;
  [class*='tableWithEdit_container'] {
    padding: 0;
    padding-top: 20px;
  }
}

[class*='checkBoxCompo_checkForm'] {
  width: 23.8% !important;
}

.input_style {
  //margin-right: 4%;
  width: 85%;
}

.none {
  display: none;
}

.containerConfig {
  padding: 43px;
}

.form {
  display: flex;
  flex-direction: column;
}

.list {
  display: flex;
}

.add {
  width: 100px !important;
  height: 48px !important;
  padding-bottom: 0;
  background-color: green;
}

.modal_container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.radio_options {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  align-items: center;

  .checkbox {
    width: 33%;
  }
}

.dependency_section {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  // width: 100%;
  gap: 3%;
}
.selectors {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  gap: 3%;
  &__sub {
    display: flex;
  }
}
