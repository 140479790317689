@use '../../modules.scss' as *;

.root {
  margin: 0;
  * + & {
    margin-top: 20px;
  }
}

.container {
  box-sizing: border-box;
  width: 100%;
  padding: 20px 20px 20px;
  height: 100%;
  display: flex;
  flex-direction: column;

  [class*='MuiFormControl-root'] {
    border: 1.5px solid rgba(120, 128, 138, 0.3);
    border-radius: 20px;
    box-sizing: border-box;
  }
}

.customContainer {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;

  [class*='MuiFormControl-root'] {
    border: 1.5px solid rgba(120, 128, 138, 0.3);
    border-radius: 20px;
    box-sizing: border-box;
  }
}

.newButton_fnc {
  @extend %primary_button;
}

.newButton_zing {
  @extend %zingPrimary_button;
}

.secondaryButton_fnc {
  @extend %secondary_button;
}

.secondaryButton_zing {
  @extend %zingSecondary_button;
}

.tableOverride div:nth-child(4) div:first-child div:first-child button:hover {
  background: none; //We've to try to only apply this effect on the button new, but for the moment we've to do it this way bcs it takes time
}

.position_button {
  display: flex;
  justify-content: flex-end;
  padding: 20px 0;
}

.newButtonExport {
  @extend %secondary_button;
}

.none {
  display: none;
}

.toolbar {
  &[class*='searchField'] {
    padding-left: 0px;
    [class*='MuiSvgIcon'] {
      color: #dbdada;
      font-size: 1.5rem;
    }
  }
}

@include media-breakpoint-down(sm) {
  .container {
    margin: 0;
    * + & {
      margin-top: 14px;
    }
  }
}
