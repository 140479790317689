@use '../../../modules.scss' as *;

.root {
  display: none;
}

@include media-breakpoint-down(sm) {
  .root {
    display: block;

    * + & {
      margin-top: 30px;
    }
  }

  .item {
    & + & {
      margin-top: 10px;
    }
  }

  .button {
    width: 100%;
  }
}