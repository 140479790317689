@use '../../modules.scss' as *;

.formControl_userType {
  width: 100%;
  position: relative;

  & .loader_wrapper {
    position: absolute;
    left: 40%;
    top: 30%;
  }

  & .roles_select {
    //margin-right: 16px;
    margin-right: 0;
  }

  & .hide {
    visibility: hidden;
  }
}

.marginOk {
  margin-top: 1rem;
}

.label {
  margin-right: 78px;
}

.register_form {
  .downloadLink {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 0.8rem;
    span {
      font-size: 0.8rem;
    }

    a {
      margin-bottom: 0.1rem;
      margin-top: 13px;
      text-decoration: none;
    }

    a:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

.register_form_fnc {
  @extend .register_form;
  .downloadLink {
    color: $secondaryColor80;
    a {
      color: $secondaryColor80;
    }

    a:hover {
      color: $primaryColor;
    }
  }
}

.register_form_zing {
  @extend .register_form;
  .downloadLink {
    color: white;
    a {
      color: white;
    }

    a:hover {
      color: $zingPrimaryColor;
    }
  }
  .questionText {
    color: white !important;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    box-shadow: 0 0 0 30px $zingPrimaryColor20 inset !important;
    -webkit-box-shadow: 0 0 0 30px $zingPrimaryColor20 inset !important;
    opacity: 0.5;
  }
}
