@use '../../modules.scss' as *;

.header_wrapper {
  margin: 30px;
  h1 {
    margin: 10px 0;
  }
  h2 {
    margin: 10px 0;
    color: $redLightColor;
    font-size: 1.5vw;
  }
}

.actions {
  display: flex;
  justify-content: flex-end;
  padding: 10px 20px;
}

.channelSelector {
  padding: 20px;

  [class*='selectInput_size'] {
    height: auto;
    width: auto;
    min-width: 400px;
  }
}

.messages {
  padding: 15px 25px;
  color: $greyFont;
}
.thread_wrapper {
  display: flex;
  padding: 10px 0;
}

.content {
  display: flex;
  flex-direction: column;
  padding: 0 10px;
}
.user {
  color: $redLightColor;
}
.unread_item {
  font-weight: bold;
}

.select_channel {
  margin: 30px;
}

.form_wrapper {
  hr {
    border-color: $redLightColor;
    opacity: 1;
    margin: 0;
    border-style: solid;
    border-width: 2px;
  }
}

.input_wrapper {
  padding: 20px 20px 0;
  margin: 0;

  .textarea {
    margin-bottom: 20px;
  }

  fieldset {
    // border: {
    //   top: 0;
    //   right: 0;
    //   left: 0;
    // }
    // border-radius: 0;
  }

  button {
    align-self: flex-start;
    margin: 0;
    padding-right: 60px;
    padding-left: 60px;
  }
}
