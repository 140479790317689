@use '../../../../modules.scss' as *;

.containerAbout {
  width: 100%;
  box-sizing: border-box;
  h3 {
    margin: 0;
  }
}

.documents {
  max-height: 25vh;
  overflow-y: scroll;
}
.containerAbout > * {
  color: $greyFont !important;
}

.expansionPanelDetails {
  flex-direction: column;
}

.resource {
  width: 100%;
}

.file {
  // background-color: 'rgb(255, 0, 0, 0.1)';
  background-color: rgba($color: $primaryColor80, $alpha: 0.1);
  margin: 0.5rem auto;
  display: flex;
  padding: 4px 35px;
  width: 100%;
  align-items: center;
  border-radius: 5px;
  justify-content: space-between;
  div {
    width: 25%;
  }

  div:last-child {
    width: 10%;
  }
}

.download_button {
  text-align: end;
}

.isGray {
  color: $greyFont !important;
}

.info {
  margin: 4rem 0;
  width: 100%;
  text-align: center;
}

.documentationContainer {
  margin: 0 auto;
  width: 100%;
}

.checkboxSection {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.checkboxSectionText {
  color: $greyFont;
  margin-right: 1rem;
  font-size: 1.2rem;
  padding: 20px 0;
}

.tableWrapper tr > td:nth-child(2) {
  padding-left: 3.8rem;
}

.tableWrapper tr > td:nth-child(4) {
  padding-left: 3.5% !important;
}
