.container {
  &__radiobuttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 48.7%;
  }
  &__modal {
    margin: 10px;
    margin-left: 0;
  }
  &__subcontainers {
    width: 48.9%;
    display: flex;
    justify-content: space-between;
  }
}
