@use '../../../../../../../../modules.scss' as *;

.btns {
  width: 100%;
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;

  div {
    margin: 0;
    padding: 0;
  }
}

.selectors {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  gap: 3%;
}

.textarea{
  margin-top: 15px;
  width: 100%;
}

@include media-breakpoint-down(sm) {
  .btns {
    display: block;
  }
  .selectors{
    display: block;
  }
}
