@use '../../modules.scss' as *;

.size_50 {
  width: 49%;
}

.green fieldset {
  border: 2px solid green !important;
}

.error {
  & p {
    color: red;
  }
}

.error fieldset {
  border: 2px solid red !important;
}

@include media-breakpoint-down(sm) {
  .size_50 {
    width: 100%;
  }
}
