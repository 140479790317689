.container {
	padding: 15px 13px;

	[class *='MuiTable-root'] {
		table-layout: auto !important;
	}

	[class *='MuiTableBody-root'] {
		[class *='MuiTableCell-align'] {
			padding: 0 20px !important;
		}
	}
}