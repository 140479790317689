.containerWithMenu {
  display: flex;
  width: 100%;
  .container {
    width: 100%;
    padding-top: 20px;
  }
  .menuRight {
    width: 15vw;
    background: white;
    border-left: 1px solid rgba(0, 0, 0, 0.12);
    position: fixed;
    top: 10.5vh;
    height: 80%;
    right: 0;
  }

  .selectContainer {
    width: 100%;
  }

}

.halfContainer {
  width: 49%;
  display: flex;
  justify-content: space-between;
}
