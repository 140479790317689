.contractContainer {
  padding: 0px 20px 0px 20px;
}

.dateContainer {
  display: flex;
  width: 50%;
}

.buttonUpload {
  width: 200px;
}

.fileNameContainer {
    display: grid;
    gap: 5px;
}

.helperText {
	color: #f44336;
	font-size: 14px;
	margin-left: 14px;
	margin-right: 14px;
}