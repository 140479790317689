@use '../../modules.scss' as *;

p {
  margin: 0;
}

.title {
  color: $redLightColor !important;
  font-size: 24px;
  background: rgba(102, 102, 102, 0.1);
  padding: 10px 30px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;

  :global(.MuiDialogTitle-root) {
    padding: 0px;
    display: flex;

    h2 {
      line-height: 1;
      font-weight: 600;
    }
  }
}

.titleRed h2 {
  color: $redLightColor !important;
  line-height: 1;
  font-weight: 600;
  font-size: 1.25rem;
  margin: 0;
}

// .dialogContent {
//   border: 2px solid red;
// }

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.paper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 40%;
  width: 20%;
  background-color: white;
  border: 2px solid #000;
  box-shadow: inset;
  padding: 2%;
}

.button2 {
  width: 40%;
  height: 100%;
  border: none;
  background-color: rgb(180, 172, 172);
}

.button {
  width: 20%;
  height: 10%;
  border: none;
  background-color: rgb(180, 172, 172);
}

.button:hover {
  background-color: rgb(151, 146, 146);
}

.button2:hover {
  background-color: rgb(151, 146, 146);
}

.position {
  display: flex;
  justify-content: space-around;
  width: 100%;
  height: 10%;
}

.test {
  max-width: auto;
}

.text {
  margin-bottom: 5%;
}

.dialogContent {
  padding: 0px !important;
  width: 800px;
}

.dialogHelperContent {
  padding: 0px !important;
  width: 500px;
}

div.documentMode {
  padding: 25px 30px 40px;
  width: 400px;
}

.followUpMode {
  padding: 0px !important;
  width: 1000px;
  min-width: 1000px;
}

.ultraWidth {
  min-width: 40vw;
  width: 40vw;
}

[class*='MuiDialogActions-root'] {
  padding: 0px 20px 12px 0px !important;
}
[class*='MuiDialogContent-root'] {
  padding: 20px 20px !important;
}

@include media-breakpoint-down(sm) {
  .dialogContent {
    width: auto;
  }
}
