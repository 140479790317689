@use '../../modules.scss' as *;

.imageMode {
  padding: 10px 5px 5px 10px;
  font-weight: bolder;
  color: $redLightColor;
  display: flex;
  justify-content: space-between;
  background: rgba(102, 102, 102, 0.1);
}

.tab {
  min-width: 132px;

  & span {
    // color: rgba(102, 102, 102, 0.8);
    font-size: 1em;
    text-transform: none;
  }
}
