@use '../../../modules.scss' as *;
@use '../fncInputsSelect.module.scss' as fncInputsSelect;

.label {
  @include fncInputsSelect.label;
}

.required {
  &:after {
    content: ' *';
  }
}

@include media-breakpoint-down(sm) {
  .label {
    @include fncInputsSelect.labelMobile;
  }
}
