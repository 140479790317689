@use '../../modules.scss' as *;

.list {
  display: flex;
  justify-content: center;
  align-items: center;
}

.item {
  & + & {
    margin-left: 25px;
  }
}

.button {
  border: 0;
  background: transparent;
  padding: 0;
  display: block;

  &:disabled {
    opacity: 0.5;
  }

  &.disabled {
    opacity: 0.5;
  }
}

.icon {
  fill: $greyT;
  display: block;
}

input[type='file'] {
  display: none;
}

@include media-breakpoint-down(sm) {
}
