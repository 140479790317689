@use '../../../../modules.scss' as *;

.form_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.date_size {
  width: 49%;
}
