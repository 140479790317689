@use '../../modules.scss' as *;

.hidden {
  display: none;
}

.modalHelper {
  padding: 20px;
  color: $greyFont;
}
