@use '../../../../../../../../modules.scss' as *;

.btns {
  width: 100%;
  margin-top: 40px;
  display: flex;
  justify-content: flex-end;

  div {
    margin: 0;
    padding: 0;
  }
}

.selectors {
  display: block;
  width: 100%;
  margin-top: 15px;
}

@include media-breakpoint-down(sm) {
  .btns {
    display: block;
  }
  .selectors{
    display: block;
  }
}
