.container {
  width: 30vw;
  display: flex;
  flex-direction: column;

  .iconMailContainer {
    align-self: flex-start;
    padding: 2px;
  }

  .textContainer {
    display: flex;
    flex-direction: column;
    padding: 7px;
    padding-top: 4px;

    .nameWrong {
      color: #ff4d48;
    }

    .nameOkay {
      color: #23a500;
    }
  }

  .wrong {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
  }
  .okay {
    @extend .wrong;
  }
}
