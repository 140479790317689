.containerTextArea {
  display: flex;
  flex-direction: column;
}

.labelsContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  .esLabel {
    color: #666666;
    flex: 0 0 35%;
  }

  .langLabel {
    color: #666666;
    flex: 0 0 62%;
  }
}

.selectIdiomaContainer {
  width: 100%;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  fieldset {
    height: 50px;
  }
}

.containerTextAreaCol {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}

.contentEs {
  flex: 0 0 35%;
  border: 1px solid rgba(118, 118, 118, 0.3);
  box-sizing: border-box;
  padding: 20px;
  color: #666666;
  font-size: 14px !important;
}
