@use '../../modules.scss' as *;

div {
  .size_10 {
    width: 11.5%;
  }

  .size_20 {
    width: 23.5%;
  }

  .size_30 {
    width: 31%;
  }

  .size_35 {
    width: 34.2%;
  }

  .size_45 {
    width: 45%;
  }

  .size_50 {
    width: 49%;
  }
  .size_55 {
    width: 55%;
  }
  .size_40 {
    width: 40%;
  }

  .size_60 {
    width: 66%;
  }

  .size_90 {
    width: 90%;
  }

  .size_95 {
    width: 95%;
  }

  .size_100 {
    width: 100%;
  }

  .size_Large {
    @extend .size_100;
  }

  .size_Medium {
    width: 49%;
  }
  .size_Small {
    width: 24%;
  }

  .hidden {
    display: none !important;
  }
}

.price {
  opacity: 0.7;
  height: 23px !important;
  width: 22px !important;
}

.hasClipBoard {
  [class*='MuiIconButton-root'] {
    position: absolute;
    top: 1px;
    right: 0px;
    z-index: 1;

    path:last-child {
      color: rgba(128, 128, 128, 0.708);
    }
  }
}

.textArea {
  [class*='MuiInputBase-input'] {
    resize: vertical;
  }
}
@include media-breakpoint-down(sm) {
  div {
    .size_10,
    .size_20,
    .size_30,
    .size_35,
    .size_45,
    .size_50,
    .size_55,
    .size_40,
    .size_60,
    .size_90,
    .size_95,
    .size_100 {
      width: 100%;
    }
  }
}

@include media-breakpoint-down(sm) {
  .hasClipBoard {
    [class*='MuiIconButton-root'] {
      display: none;
    }
  }
}
