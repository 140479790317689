.btns {
  margin: 5% 20%;
}

.form {
  padding: 0 20px;
}

.containerForms {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.containerActors {
  width: 49%;
  display: flex;
  flex-direction: row;
  margin-top: 30px;
}
