@use '../../../modules.scss' as *;

.imageContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;

  [class*='MuiButtonBase-root'] {
    margin: 0 10px;
  }
}
