@use '../../modules.scss' as *;

.typesWrapper {
  background-color: white;
  padding: 10px 20px 15px;
  border: 2px;
  width: 49%;
  box-sizing: border-box;
  border: 1px solid #e7e7e7;
  border-radius: 4px;
}

.typesTitles {
  font-size: 14px;
  color: $greyFont;
  margin: 0 0 10px;
}

.typesItem {
  display: flex;

  & + & {
    margin-top: 4px;
  }

  [class*='MuiSvgIcon-root'] {
    width: initial;
    height: initial;
  }
}

.typesText {
  font-size: 14px;
  color: $greyFont;

  [class*='MuiSvgIcon-root'] + & {
    margin-left: 4px;
  }
}
