.containerItem {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  margin-bottom: 20px;
  opacity: 0.7;

  &:hover {
    opacity: 1;
  }
}

.buttonContainer {
  display: flex;
  align-items: center;
}

.form_container {
  width: 48.5%;
  display: flex;
  height: 132px;
  justify-content: space-between;
  position: relative;
  textarea {
    height: 76px !important;
    margin-bottom: 20px;
  }
  fieldset {
    opacity: 1;
  }
}

.iconContainer {
  display: flex;
  align-items: center;
}

.iconButton {
  svg {
    height: 35px;
    width: 35px;
  }
}

.notExpanded {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.notExpandedItem {
  flex: 0 0 45%;
  background: rgba(164, 164, 182, 0.2);
  padding: 12px;
}

.notExpandedItemTextfield {
  flex: 0 0 45%;
  border: 1px solid #a4a4b6;
  padding: 10px;
  border-radius: 5px;
}

.relativeContainer {
  position: absolute;
  top: 100px;
  right: 10px;
  width: 60px;
  display: flex;
  justify-content: space-between;

  button {
    padding: 0px;
    min-width: 20px;
  }
}
