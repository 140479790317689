@use '../../../../modules.scss' as *;

.downloadButton {
  border: none;
  background: none;
  width: auto;
}

.entity_ref_user_id {
  width: 49%;
}
