@use '../../../../modules.scss' as *;

.containerAbout {
  width: 100%;
  box-sizing: border-box;
  //padding: 20px;
  h3 {
    margin: 0;
  }
  h1 {
    color: $primaryColor80;
  }

  h1:last-child {
    margin-top: 5rem;
  }
}

.info {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.addItem {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  flex-wrap: wrap;
  margin-bottom: 1.5rem;
}
.size_50 {
  width: 49%;
}

.table_assing {
  [class*='tableWithEdit_container'] {
    padding: 0;
    padding-top: 2px;
  }
  margin-bottom: 5rem;
}

.secondTitle {
  margin-top: 5rem;
}

.table_assing tr > td:nth-child(3) {
  padding-left: 4.4rem;
}

.table_assing tr > td:nth-child(6) {
  padding-left: 1rem !important;
}

.addItemPay {
  width: 100%;
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  margin-bottom: 1.5rem;
  div {
    margin-right: 5px;
  }
}
