@use '../../../modules.scss' as *;

.container {
  overflow-y: auto;
}

.renewal_tag {
  border: 1px solid grey;
  font-size: 12px;
  padding: 4px 8px;
  margin-top: 14px;
}

@include media-breakpoint-down(sm) {
  .container {
    display: none;
  }
}
