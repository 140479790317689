@use '../../modules.scss' as *;

.root {
  box-sizing: border-box;
  height: auto;
  @extend %pageStyles;

  [class*='tableWithEdit_container'] {
    padding: 0;
  }

  [class*='stateMenu_root'] + & {
    border-top: 1px solid $greyLight;
  }
}

.redBullet {
  color: $zingPrimaryColor80;
}

.yellowBullet {
  color: $orangeBubble;
}

.greenBullet {
  color: $greenBubble;
}

.hiddenDesktop {
  display: none;
}

@include media-breakpoint-down(sm) {
  .root {
    padding: 22px 20px;
  }
  .hiddenDesktop {
    display: block;
  }
}
