@use '../../modules.scss' as *;

.container {
  width: 100%;
  & .title {
    font-size: 1.1em;
    color: $redLightColor;
    font-weight: bold;
  }

  & .subtitle {
    margin-bottom: 0.5rem;
    margin-left: 0.5rem;
    color: $redLightColor;
    font-size: 1em;
  }

  & .dItem {
    grid-area: dItem;
  }

  & .aItem {
    grid-area: aItem;
  }

  & .fItem {
    grid-area: fItem;
  }

  & .oItem {
    grid-area: oItem;
  }

  & .area {
    color: $greyFont;
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto;
    grid-template-areas: 'dItem aItem' 'fItem oItem';
    & > * {
      margin-top: 1rem;
    }
  }
}
