.form_container {
  padding: 20px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.itemContainer {
  flex: 0 0 49%;
  .textInput {
    margin-bottom: 10px;
  }
  input {
    padding: 10px 14px;
  }
}

.mainTextArea {
  flex: 0 0 79%;
  .textInput {
    margin-bottom: 10px;
    color: #666666;
  }
  input {
    padding: 10px 14px;
  }
}

.textTranslate {
  flex-basis: 100%;
  display: flex;
  justify-content: space-between;
  .itemContainer {
    flex: 0 0 47%;
  }
}

.selectTranslation {
  height: 40px;
}

.buttonContainer {
  display: flex;
  align-items: center;
  margin-top: 5px;
  flex: 0 0 19%;
}

.langContainer {
  flex: 0 0 49%;
}
