.buttonContainer {
  width: 230px;
  display: flex;
  justify-content: space-between;
  margin-left: auto;
  margin-bottom: 20px;
}

.modalHelp {
  padding: 20px;
  color: #666666;
}