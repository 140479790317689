@use '../../modules.scss' as *;

.container {
  margin: 1% 0;
  background: white;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  display: grid;
  grid-template-columns: 20% 50% 30%;
  align-items: center;
  &__checkbox {
    padding: 20%;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  &__label {
    &__box {
      border-left: 1px solid $zingSecondaryColor10;
      padding-left: 15px;
    }
    &__title {
      color: $primaryColor80;
      font-weight: 500;
    }
  }
  &__altLabel {
    &__box {
      border-left: 1px solid $zingSecondaryColor10;
    }
    &__title {
      color: $secondaryColor40;
      text-align: center;
      font-weight: 500;
    }
  }
}
