.cell_format {
	//width: 98px;
	height: 20px;
	padding-left: 3px;
	overflow: hidden;
}

.cell_format_number {
	@extend .cell_format;
	text-align: left;
}

.cell_format_modal {
	//width: 98px;
	height: 20px;
	padding-left: 3px;
	overflow: hidden;
	cursor: pointer;
}

.tableCell {
	width: 114px;
	height: 25px;
}

.tableCellFixed {
	top: auto;
	width: 5em;
}

.tableHeadFilterFixed {
	top: auto !important;
	width: 5em !important;
	position: sticky !important;
	z-index: 1 !important;
	background-color: white !important;
}

//MuiInput-input
.cell_format_number_input {
	[class*='MuiInput-input'] {
		@extend .cell_format;
		text-align: left;
	}	
}