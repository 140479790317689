@use '../../../../modules.scss' as *;

.justificationTableContainer {
  width: 100%;
}

.formFields {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.actionButton {
  padding-bottom: 16px;
  text-align: right;
}

@include media-breakpoint-down(sm) {
  .justificationTable {
    font-size: 0.8rem;
  }
 }
