.wrapper {
}

.container {
  padding: 0px;
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  [class*='MuiTab-wrapper'] {
    margin-left: 0;
  }
}

.errors {
  display: flex;
  flex-direction: column;
}

.okey {
  margin: 1%;
  color: green;
}

.wrong {
  margin: 1%;
  color: red;
}

.modal {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
