.filter {
  display: flex;
  align-items: flex-start;
  margin-left: 20px;
  [class*='selectInput_size_20'] {
    margin-right: 15px;
  }
  [class*='MuiButton-containedPrimary'] {
    margin-top: 5px;
  }
}
