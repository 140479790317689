@use '../../modules.scss' as *;

.root {
  display: grid;
  grid-gap: 20px;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-content: end;

  * + & {
    margin-top: 20px;
  }

  * + .inputLink {
    margin-top: 10px;
  }
}

.field {
  [class*='fncInputText_input'],
  [class*='fncSelect_select'],
  [class*='fncDatePicker_input'],
  [class*='MuiFormControl-root'],
  [class*='fncTextArea_textarea']  {
    min-width: auto;
    width: 100%;
  }

  [class*='MuiInputBase-formControl'] {
    margin-bottom: 0;
  }

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.Small { 
  grid-column: span 1;
}

.Medium { 
  grid-column: span 2;
}

.Large { 
  grid-column: span 4;
}

.poblacion {
  position: relative;
  grid-column: span 2;
}

.description {
  @include font($ff: $ff-primary, $fs: rem(16px), $lh: rem(24px), $fw: 400, $co: $secondaryColor);

  * + & {
    margin-top: 30px;
  }
  grid-column: span 4;
}

@include media-breakpoint-down(sm) {

  .root {
    margin-top: 20px;
  }

  .field {
    .radio + & {
      border-top: 1px solid $greyLight;
      padding-top: 20px;
    }

    grid-column: span 4;
  }

  .poblacion {
    grid-column: span 4;
  }
}
