
.containerWithPadding {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}


.inputWrapper{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.boxContainer {
  display: flex;
  flex-direction: column;
  margin: 20px;
  padding: 40px;
  padding-top: 20px;
  background: white;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  h2 {
    margin: 0px;
    margin-bottom: 20px;
  }
}


.boxContainerFirstDate{
  display: flex;
  flex-direction: column;
  margin: 0px 20px;
  padding: 40px;
  padding-top: 10px;
  background: white;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  width: 22%;
  height: 59px;
  min-width: 207px;

  h2 {
    margin: 0px;
    margin-bottom: 20px;
  }
}
.generalContainer {
  display: flex;
  flex-direction: column;
}

.firstRow{
  display: flex;
  flex-direction: row ;
}
