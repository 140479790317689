@use '../../modules.scss' as *;

.wrapper {
  box-sizing: border-box;
  width: 100%;
  h4 {
    margin: 1%;
  }

  [class*='tableWithEdit_container'] {
    padding: 0;
    margin-bottom: 40px;
  }
}

.buttons_list {
  padding: 0;
}

.view_file_label {
  display: inline-block;
  height: 24px;
  cursor: pointer;
}

.containerAddDocument {
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  width: 100%;

  .description {
    width: 100%;
  }

  .descriptionDocument {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
}

.button {
  text-align: right;
}

.buttonDisabled {
  display: none;
}

.responseDiv {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: auto;
}

.view_file[type='file'] {
  display: none;
}

.textLegend {
  margin: 0;
  color: $greyFont;
}

.okIcon {
  display: flex;
  color: $greyFont;
}

.docInfo {
  color: $greyFont;
  font-size: 14px;
  margin-top: 10px;
}

%newButton {
  padding: 12px 50px;
  text-transform: none;
  border: 1px solid #66666600;
  border-radius: 5px;
  color: white;
  font-size: 16px;
}

.newButton_fnc {
  @extend %newButton;
  background: $primaryColor80;
}

.newButton_zing {
  @extend %newButton;
  background: $zingPrimaryColor80;
}

.newButton_fnc:hover {
  @extend .newButton_fnc;
  transition: 0.4s;
}

.newButton_zing:hover {
  @extend .newButton_zing;
  transition: 0.4s;
}

.modal {
  display: flex;
  justify-content: space-evenly;
  align-items: baseline;
  margin-top: 20px;
}

.btn_modal {
  display: flex;
  justify-content: center;
  margin-bottom: 3%;
}

.btn_modal {
  padding: 11px 26px;
  text-transform: none;
  border: 1px solid #66666600;
  border-radius: 5px;
  color: white;
  font-size: 16px;
}

.agree {
  @extend .btn_modal;
  background-color: green;
}

.btn_modal_alone {
  display: flex;
  justify-content: center;
  margin-bottom: 3%;

  padding: 11px 26px;
  text-transform: none;
  border: 1px solid #66666600;
  border-radius: 5px;
  color: white;
  font-size: 16px;
  margin: 1rem 0;
}

.footnote {
  margin: 0px;
  width: 100%;
  margin-top: 10px;
}

.testing {
  color: inherit;
}
.sendButton {
  background-color: green !important;
  color: white !important;
}

/* NEW */

.description {
  @include font($ff: $ff-primary, $fs: rem(14px), $lh: rem(22px), $fw: 400, $co: $grey);
}

@include media-breakpoint-down(sm) {
  .textLegend {
    margin-top: 20px;
    @include font($ff: $ff-primary, $fs: rem(14px), $lh: rem(22px), $fw: 400, $co: $grey);
  }
}
