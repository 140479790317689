.totals_container {
  margin-top: 10px;
  display: inline-flex;
  gap: 40px;
}

.formContainer {
  .mainContainer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}



.hidden {
  display: none !important;
  width: 100% !important;
}

.showUp {
  display: inline-flex;
  width: 100% !important;
  font-size: 14px;
  margin-bottom: 20px;
  color: red;
}

.manualError {
  color: red;
  font-size: 15px;
  margin-bottom: 20px;
}

.size_50 {
  width: 49%;
}

.green fieldset {
  border: 2px solid green !important;
}

.extra_padding {
  padding-top: 20px;
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
}