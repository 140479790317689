@use '../../../../modules.scss' as *;

.container {
  width: 100%;
  display: flex;
  flex-direction: column;

  * + & {
    margin-top: 20px;
  }

  & + [class*='formContainer_root'] {
    margin-bottom: 40px;
  }
}

.containerQuestion {
  display: flex;
  flex-direction: column;
  width: 100%;

  * + & {
    margin-top: 40px;
  }
}

.containerInputs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.containerRadioButtons {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.title_textInput {
  color: $greyFont;
  margin-bottom: 20px;
  font-weight: 600;
  width: 31%;
}

.adjustment_container {
  display: inline-flex;
  justify-content: flex-end;
  width: 100%;
}

.containerWorkingStart {
  display: flex;
  justify-content: space-between;
}

.container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 2rem;
}

.containerFollowup {
  grid-template-columns: repeat(4, 1fr);
}

.containerFullGridRow {
  grid-column: 1 / span 2;
}

.title_textInput {
  color: $greyFont;
  margin-bottom: 20px;
  font-weight: 600;
}

.borderTop {
  border-top: 2px solid #c0c0c0;
  padding-top: 2.5rem;
}

.externalAmountsSection {
  .container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 2rem;
  }

  .containerFollowup {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 2rem;

    .gapUntilLastColumn {
      grid-column: 4 / 4;
    }
  }

  .containerContrib {
    grid-column-start: 2;
  }
}

.highlighted {
  label {
    color: #111111 !important;
  }

  input {
    font-weight: bold;
  }

  &.isTotal {
    margin-top: auto;
  }

  & + .field {
    margin-top: 40px;
  }
}

.topBorder {
  width: 100%;
  margin-bottom: 20px;
  border: 1px solid #bababa91;
}

.columns {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;
  gap: 0px 20px;

  &.is2Cols {
    grid-template-columns: 2fr 1fr;
  }

  & + & {
    margin-top: 60px;
  }
}

.columnsWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.mecScholarshipContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

@include media-breakpoint-down(sm) {
  .container {
    grid-template-columns: 1fr;
    * + & {
      margin-top: 20px;
    }

    & + [class*='formContainer_root'] {
      margin-bottom: 0px;
    }
  }

  .containerFullGridRow {
    margin-top: 20px;
    grid-column: 1 / span 1;
  }

  .containerWorkingStart {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .form {
    * + & {
      margin-top: 20px;
    }
  }

  .containerRadioButtons {
    grid-template-columns: 1fr;
  }

  .columns {
    display: block;

    &.hasBorder {
      padding: 25px 15px 20px;
      border: 1px solid $greyLight;
    }

    * + & {
      margin-top: 20px;
    }
  }

  .columnsWrapper {
    padding: 20px 0;
  }

  .highlighted {
    &.isTotal {
      margin-top: 10px;
    }
  }

  .containerQuestion {
    * + & {
      margin-top: 20px;
    }
  }

  .containerFollowup {
    grid-template-columns: 1fr;
    margin-top: 20px;
  }


}
