@mixin background($imgpath, $position: center, $size: null, $repeat: no-repeat) {
  background: {
    image: url($imgpath);
    position: $position;
    size: $size;
    repeat: $repeat;
  }
}

@mixin font($ff: false, $fs: false, $fw: false, $fst: false, $lh: false, $co: false, $ls: false, $lsC: false) {
  @if $ff {
    font-family: $ff;
  }
  @if $fs {
    font-size: $fs;
  }
  @if $fw {
    font-weight: $fw;
  }
  @if $fst {
    font-style: $fst;
  }
  @if $lh {
    line-height: $lh;
  }
  @if $co {
    color: $co;
  }
  @if $ls {
    letter-spacing: $ls;
  }
  @if $lsC {
    @include letter-spacing($ls);
  }
}
