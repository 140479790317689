@use '../../modules.scss' as *;

.root {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  padding: 19px 14px 18px;
  margin-bottom: 22px;
  border-radius: 4px;
  width: 49%;
  background: #f0f0f0;
  box-sizing: border-box;

  strong {
    color: $greenBubble;
  }

  &.isSuperior {
    strong {
      color: #b50d0d;
    }
  }
}

.infoChipIcon {
  position: absolute;
  top: 16px;
  left: 14px;

  &.isWarning {
    color: #b50d0d;
  }

  &.isCheck {
    color: $greenBubble;
  }
}

.infoChipText {
  font-size: 12px;
  color: $greyFont;
  line-height: 15px;
  font-weight: 400;
  padding-left: 30px;
}

@include media-breakpoint-down(sm) {
  .root {
    width: 100%;
    margin: 0;
    justify-content: center;
    padding: 10px 15px;

    * + & {
      margin-top: 4px;
    }
  }

  .infoChipIcon {
    top: 0;
    bottom: 0;
    margin: auto;
  }
}
