@use '../../modules.scss' as *;

.wrapper {
  //background-color: turquoise;
  display: flex;
  flex-direction: column;
  color: $greyFont;
  width: 100%;
  & .title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    & .label {
      font-size: 1.1em;
      padding-left: 0.5rem;
      border-left: 6px solid $redLightColor;
    }
  }
}
