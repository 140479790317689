@use '../../modules.scss' as *;

.root {
  min-height: 100vh;
  width: 100%;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 50px 60px;
  box-sizing: border-box;

  &.is_zing {
    background-image: url(../../images/fondo_zing.jpg);
  }

  &.is_fnc {
    background-image: url(../../images/fondo_fnc.png);
  }
}

.logo {
  margin: 0 auto;
  width: 140px;
  display: block;
}

.title {
  @include font($ff: $ff-secondary, $fs: rem(30px), $lh: rem(34px), $fw: 400, $co: $black);
  border-left: 15px solid;
  padding-left: 20px;
  margin: 0;

  .root.is_zing & {
    color: $white;
    border-color: $white;
  }

  .root.is_fnc & {
    color: $secondaryColor;
    border-color: $primaryColor;
  }

  * + & {
    margin-top: 60px;
  }
}

.container {
  max-width: 540px;

  * + & {
    margin-top: 40px;
  }
}

.subtitle {
  @include font($ff: $ff-secondary, $fs: rem(24px), $lh: rem(28px), $fw: 400, $co: $black);
  margin: 0;

  * + & {
    margin-top: 50px;
  }

  .root.is_fnc & {
    color: $primaryColor;
  }

  .root.is_zing & {
    color: $white;
  }
}

.loginForm {
  * + & {
    margin-top: 38px;
  }
}

#cookies-button {
  opacity: 1 !important;
  margin-right: 20px !important;
  padding: 15px 30px !important;
  border-radius: 5px !important;
  text-transform: capitalize;
  font-weight: bold;
}

%button {
  @extend #cookies-button;
  background: white !important;
}

.button_fnc {
  @extend %button;
  color: $primaryColor !important;
}

.button_zing {
  @extend %button;
  color: $zingPrimaryColor !important;
}

%decline_button {
  @extend #cookies-button;
  color: white !important;
}

.decline_button_fnc {
  @extend %decline_button;
  background: $primaryColor80 !important;
}

.decline_button_zing {
  @extend %decline_button;
  background: $zingPrimaryColor80 !important;
}

.link {
  &:visited {
    color: white;
  }

  &:link {
    color: white;
  }

  &:active {
    color: white;
  }

  &.linkMasInformacion {
    font-weight: bold;
    text-decoration: none;
  }
}


@include media-breakpoint-down(sm) {
  .root {
    padding: 15px 20px;
  }

  .logo {
    width: 82px;
  }

  .title {
    @include font($fs: rem(26px), $lh: rem(32px));
  }

  .subtitle {
    @include font($fs: rem(22px), $lh: rem(36px));

    * + & {
      margin-top: 80px;
    }
  }

  .loginForm {
    * + & {
      margin-top: 30px;
    }
  }

  .decline_button_zing,
  .button_zing,
  .button_fnc,
  .decline_button_fnc {
    background: transparent !important;
    color: $white !important;
  }

  .cookieMessage {
    font-size: 12px;
    line-height: 16px;
  }
}
