@use '../../modules.scss' as *;

.root {
  * + & {
    margin-top: 40px;
  }
}

.container_block {
  margin-bottom: 40px;
  width: 100%;
}

.container_input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.container_block_lowMargin {
  @extend .container_block;
  margin-bottom: 10px;
}

.container_input_space {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.smallTitle {
  font-weight: bold;
  margin-bottom: 20px;
}

.smallTitle_fnc {
  @extend .smallTitle;
  color: $primaryColor80;
}

.smallTitle_zing {
  color: $zingPrimaryColor80;
}

@include media-breakpoint-down(sm) {
  .root {
    * + & {
      margin-top: 18px;
    }

    [class*='MuiInputBase-root'] {
      margin-bottom: 0;
    }

    * + [class*='MuiFormControl-root'] {
      margin-top: 14px;
    }

    [class*='MuiFormControl-root'] {
      width: 100%;
    }

    [class*='MuiFormHelperText-contained'] {
      position: initial;
    }

    [class*='messageInfo_root'] + [class*='MuiFormControl-root'] {
      margin-top: 20px;
    }

    [class*='MuiInputBase-input'] {
      @include font($ff: $ff-primary, $fs: rem(14px), $lh: rem(17px), $fw: 400, $co: $greyFont);
    }

    label[class*='MuiFormLabel-root'] {
      @include font($ff: $ff-primary, $fs: rem(14px), $lh: rem(17px), $fw: 400, $co: $grey);
      margin: 0;
    }

    [class*='radioButtons_questionText'],
    [class*='MuiFormControlLabel-label'] {
      @include font($ff: $ff-primary, $fs: rem(14px), $lh: rem(17px), $fw: 400, $co: $grey);
    }

    [class*='MuiRadio-root'] {
      padding: 5px;

      [class*='MuiSvgIcon-root'] {
        width: 12px;
        height: 12px;
      }
    }

    [class*='MuiFormControlLabel-root'] {
      margin: 0;
    }

    [class*='MuiFormGroup-root'] {
      gap: 10px 15px;
    }
  }

  .container_block {
    margin-bottom: 0px;
  }

  .content {
    padding: 20px 0;

    &.noTitle {
      padding: 0;
    }
  }

  .container_input {
    display: block;

    * + & {
      margin-top: 20px;
    }
  }
}
