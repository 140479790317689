
.form {
  width: 100%;
}

.summary {
  width: 100%;
  margin-top: 40px;
  display: flex;
  justify-content: flex-start;
  gap: 15px;
}
