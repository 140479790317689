@use '../../../../../modules.scss' as *;
@import '../../../Modals/CreateInternalProjects/createInternalProjects.module.scss';

.root {
  display: grid;
  grid-gap: 20px;
  margin-bottom: 60px;
  width: 100%;
  grid-template-columns: auto auto auto auto;
  align-content: end;
}

.space_input {
  width: 50%;
}

@include media-breakpoint-down(sm) {
  .root {
    grid-gap: 10px;

    [class*='tableWithEdit_container'] {
      display: none;
    }

    > .submenuInternalProjects {
      margin-top: 20px;
      height: 50px;
    }
  }

  .title {
    display: block;
    @include font($ff: $ff-primary, $fs: rem(24px), $lh: rem(28px), $fw: 400, $co: $primaryColor);
  }
}
