%avatar {
  border-radius: 50%;
}

.avatar_big {
  @extend %avatar;
}

.avatar_medium {
  @extend %avatar;
  height: auto;
  width: 40px;
  background-color: white;
  box-shadow: 0px 2px 5px #d5d2d2;
}

.avatar_small {
  @extend %avatar;
  height: 2vw;
  width: 2vw;
}
