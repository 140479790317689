.select {
  &__formControl {
    min-width: 120px;
    width: 150px;
    max-width: 200px;
    margin: 8px;
  }
  &__close {
    color: rgba(0, 0, 0, 0.4);
    width: 18px !important;
    cursor: pointer;
  }
}
