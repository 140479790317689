@use '../../../../modules.scss' as *;

.helperText {
  color: #f44336;
  font-size: 14px;
  margin-left: 14px;
  margin-right: 14px;
}

.entity_types_contanier {
  display: flex;
  flex-direction: column;

  .error_message {
    color: $redLightColor;
  }

  .entity_type {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
}

.entity_type_provider {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.type_person {
  display: inline-block;
  border: 1px solid #5a59574d;
  border-radius: 4px;
  padding: 4px;
  margin-bottom: 4px;

  div {
    margin-left: 5px;
    margin-bottom: 0px;
  }

  label {
    margin-bottom: 0px;
  }

  legend {
    display: none;
  }
}
