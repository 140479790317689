@use '../../../../modules.scss' as *;

.form {
  * + & {
    margin-top: 20px;
  }
}

.containerWithRadio {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.checkBoxContainer {
  width: 20%;
  height: 50px;
  align-items: center;
  padding-left: 5px;
  padding-top: 5px;
}

.title_textInput {
  color: $greyFont;
  margin-bottom: 20px;
  font-weight: 600;
  width: 31%;
}

.adjustment_container {
  display: inline-flex;
  justify-content: flex-end;
  width: 100%;
}

.adminReviewModalContainer {
  width: 800px;
}

.adminReviewStudentInfoPopUp {
  display: block;
  line-height: 1.5;
}

.columns {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  gap: 0px 20px;

  &.is2Cols {
    grid-template-columns: 2fr 1fr;
  }

  & + & {
    margin-top: 60px;
  }
}

@include media-breakpoint-down(sm) {
  .root {
    * + & {
      margin-top: 20px;
    }
  }

  .form {
    * + & {
      margin-top: 18px;
    }
  }

  .columns {
    display: block;

    &.hasBorder {
      padding: 25px 15px 20px;
      border: 1px solid $greyLight;
    }

    * + & {
      margin-top: 20px;
    }
  }

  .columnsWrapper {
    padding: 20px 0;
  }
}
