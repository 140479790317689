@use '../../modules.scss' as *;

.actions {
  display: flex;
  justify-content: space-around;
  margin-bottom: 1rem;
  width: 80%;
  a {
    color: $redLightColor;
    font-size: 15px;
    line-height: 12px;
    text-decoration: underline;
  }
  &__zing {
    @extend .actions;
    a {
      color: white;
    }
  }
  &__fnc {
    @extend .actions;
  }
}
