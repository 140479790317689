@use '../../../../modules.scss' as *;

.poblacionContainer {
  position: absolute;
  display: grid;
  grid-gap: 20px;
  width: 100%;
  grid-template-columns: 100px auto;
  align-content: end;
}

.zipCode {
  grid-column: 1;
}

.city {
  grid-column: 2;
}

@include media-breakpoint-down(sm) {
  .poblacionContainer {
    position: relative;
  }

  .city, .zipCode {
    grid-column: span 2;
  }
}
