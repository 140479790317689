@use '../../../modules.scss' as *;

.field {
  * + & {
    margin-top: 20px;
  }

  [class*='fncSelect_select'] {
    max-width: 400px;
  }
}
