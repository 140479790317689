$standard-position: 20px;

.top {
  top: 50%;
  transform: translateY(-50%);
}

.bottom {
  bottom: 15px;
}

.left {
  left: $standard-position;
}

.right {
  right: $standard-position;
}

.avatar {
  width: 100%;
  height: auto;
  border-radius: 50%;
}

.drawer_profile {
  width: 20%;
}

.genially_link {
  display: flex;
}

.genially {
  color: white;
  font-size: 2.5em !important;
}